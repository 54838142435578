import { collection, getDocs, query, where } from "firebase/firestore";
import { db, auth } from "./firebaseConfig"; // Assurez-vous que le chemin est correct
import { onAuthStateChanged } from "firebase/auth";

export const fetchUserSubscriptions = (setCurrentUser) => {
  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        const fetchData = async () => {
          try {
            const usersCollection = collection(db, "Users");
            const userQuery = query(usersCollection, where("email", "==", user.email));
            const userSnapshot = await getDocs(userQuery);
            const usersList = [];

            for (const userDoc of userSnapshot.docs) {
              const userData = userDoc.data();
              const subscriptionsCollection = collection(userDoc.ref, "subscriptions");
              const subscriptionsSnapshot = await getDocs(subscriptionsCollection);

              const subscriptionsList = subscriptionsSnapshot.docs.map((subscriptionDoc) => {
                const subscriptionData = subscriptionDoc.data();

                const startDate = new Date(subscriptionData.current_period_start * 1000);
                const endDate = new Date(subscriptionData.current_period_end * 1000);
                
                return {
                  email: userData.email,
                  name: subscriptionData.items[0]?.price.product.name || "Unknown Product",
                  status: subscriptionData.status,
                  startDate,
                  endDate,
                };
              });

              // Trier les abonnements par date de création (du plus récent au plus ancien)
              subscriptionsList.sort((a, b) => b.startDate - a.startDate);

              // Ajouter l'abonnement le plus récent à la liste des utilisateurs
              if (subscriptionsList.length > 0) {
                usersList.push(subscriptionsList[0]);
              }
            }

            resolve(usersList);
          } catch (err) {
            console.error("Error fetching user with subscriptions:", err);
            reject(err);
          }
        };

        fetchData();
      } else {
        reject("No user is signed in");
      }
    });
  });
};

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../authContext";
import { db } from "../firebaseConfig";
import {
  doc,
  getDocs,
  collection,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import {
  Box,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Tabs,
  Tab,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Card,
  CardContent,
} from "@mui/material";
import { analyzeAndUpdateInterviewFeedback } from "../api/fetchOpenAiData";

function InterviewReport() {
  const [loading, setLoading] = useState(true); // Démarrer en chargeant
  const [reportData, setReportData] = useState([]);
  const [error, setError] = useState("");
  const { currentUser } = useAuth();
  const [tabValue, setTabValue] = useState(0);
  const [selectedOffre, setSelectedOffre] = useState("");

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChangeOffre = (event) => {
    setSelectedOffre(event.target.value);
  };

  const fetchReport = async () => {
    setLoading(true);
    setError("");
    try {
      const offresRef = collection(db, "offres_emploi");
      const q = query(
        offresRef,
        where("ownerid", "==", currentUser.email),
        orderBy("datePublication", "desc")
      );

      const querySnapshot = await getDocs(q);
      const reportResults = [];

      for (const docSnap of querySnapshot.docs) {
        const offreData = docSnap.data();
        const { titre, l_candidats, interview_session } = offreData;

        if (l_candidats && interview_session) {
          const candidats = l_candidats.map((candidat) => {
            const sessions = interview_session.filter(
              (sess) => sess.idcandidat === candidat.email
            );

            const questionsReponses = sessions.map((sess) => ({
              question: sess.question,
              reponse: sess.reponse,
              commentaire: sess.commentaire || "Aucun commentaire", // Ajout de la colonne commentaire
              note: sess.note || "Pas de note", // Ajout de la colonne note
            }));

            return {
              name: candidat.name,
              questionsReponses: questionsReponses,
              interviewnote: candidat.interviewnote ? `${candidat.interviewnote}%` : null,
              interviewcomment: candidat.interviewcomment,
            };
          });

          reportResults.push({ id: docSnap.id, titre, candidats });
        }
      }

      setReportData(reportResults);
    } catch (err) {
      console.error("Erreur lors de la récupération des données de rapport:", err);
      setError("Erreur lors de la récupération des données de rapport.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const initializeReport = async () => {
      try {
        // Exécutez d'abord analyzeAndUpdateInterviewFeedback
        await analyzeAndUpdateInterviewFeedback();

        // Ensuite, récupérez les données du rapport
        if (currentUser) {
          fetchReport();
        }
      } catch (err) {
        console.error("Erreur lors de l'analyse des feedbacks:", err);
        setError("Erreur lors de l'analyse des feedbacks.");
        setLoading(false);
      }
    };

    initializeReport();
  }, [currentUser]);

  const filteredReportData = selectedOffre
    ? reportData.filter((offre) => offre.id === selectedOffre)
    : reportData;

  return (
    <Box sx={{ p: 4, backgroundColor: "#f0f2f5", minHeight: "100vh" }}>
      <Typography variant="h4" gutterBottom align="center">
        Rapport d'Interview
      </Typography>

      <Box sx={{ mb: 4, display: "flex", justifyContent: "center" }}>
        <FormControl sx={{ minWidth: 300 }}>
          <InputLabel id="select-offre-label">Sélectionnez une offre</InputLabel>
          <Select
            labelId="select-offre-label"
            value={selectedOffre}
            label="Sélectionnez une offre"
            onChange={handleChangeOffre}
          >
            <MenuItem value="">Toutes les offres</MenuItem>
            {reportData.map((offre) => (
              <MenuItem key={offre.id} value={offre.id}>
                {offre.titre}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          centered
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Questions et Réponses" />
          <Tab label="Notes Globales et Commentaires" />
        </Tabs>
      </Box>

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error" align="center">{error}</Typography>
      ) : (
        <>
          {tabValue === 0 && filteredReportData.length > 0 && (
            filteredReportData.map((offre, index) => (
              <Card key={index} sx={{ mb: 4 }}>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Offre: {offre.titre}
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead sx={{ backgroundColor: "#1976d2", color: "white" }}>
                        <TableRow>
                          <TableCell style={{ color: "white" }}>Nom du Candidat</TableCell>
                          <TableCell style={{ color: "white" }}>Question</TableCell>
                          <TableCell style={{ color: "white" }}>Réponse</TableCell>
                          <TableCell style={{ color: "white" }}>Commentaire</TableCell>
                          <TableCell style={{ color: "white" }}>Note</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {offre.candidats.map((candidat, idx) => (
                          <React.Fragment key={idx}>
                            {candidat.questionsReponses.map((qr, qidx) => (
                              <TableRow key={`${idx}-${qidx}`}>
                                {qidx === 0 && (
                                  <TableCell rowSpan={candidat.questionsReponses.length}>
                                    {candidat.name}
                                  </TableCell>
                                )}
                                <TableCell>{qr.question}</TableCell>
                                <TableCell>{qr.reponse}</TableCell>
                                <TableCell>{qr.commentaire}</TableCell>
                                <TableCell>{qr.note}</TableCell>
                              </TableRow>
                            ))}
                          </React.Fragment>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            ))
          )}

          {tabValue === 1 && filteredReportData.length > 0 && (
            filteredReportData.map((offre, index) => (
              <Card key={index} sx={{ mb: 4 }}>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Offre: {offre.titre}
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead sx={{ backgroundColor: "#1976d2", color: "white" }}>
                        <TableRow>
                          <TableCell style={{ color: "white" }}>Nom du Candidat</TableCell>
                          <TableCell style={{ color: "white" }}>Note Globale</TableCell>
                          <TableCell style={{ color: "white" }}>Commentaire Global</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {offre.candidats.map((candidat, idx) => (
                          <TableRow key={idx}>
                            <TableCell>{candidat.name}</TableCell>
                            <TableCell>{candidat.interviewnote}</TableCell>
                            <TableCell>{candidat.interviewcomment}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            ))
          )}
        </>
      )}

      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <Button variant="contained" color="primary" onClick={fetchReport}>
          Actualiser le Rapport
        </Button>
      </Box>
    </Box>
  );
}

export default InterviewReport;

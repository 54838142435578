import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  LinearProgress,
} from "@mui/material";
import { useAuth } from "../authContext";
import {
  collection,
  getDocs,
  deleteDoc,
  query,
  where,
  updateDoc,
  limit,
  orderBy,
} from "firebase/firestore";
import { db } from "../firebaseConfig";
import { fetchOpenAiData, generateAndStoreQuiz } from "../api/fetchOpenAiData";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const Evaluation = () => {
  const { currentUser } = useAuth();
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [score, setScore] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [quizId, setQuizId] = useState("");
  const [showAnswer, setShowAnswer] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [userAnswers, setUserAnswers] = useState([]);
  const [isQuizFinished, setIsQuizFinished] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  const handleJsonError = (jsonError) => {
    console.error("Erreur lors de l'analyse du JSON généré :", jsonError);
    setError(
      "La création du quiz a été interrompue. Merci de tenter à nouveau."
    );
    navigate("/evaluation"); // Redirect to /preparation
  };

  useEffect(() => {

//------------------------------------------------------------

const fetchAndStoreQuiz = async () => {
  if (!currentUser) {
    setLoading(false);
    return;
  }

  try {
    console.log("Fetching quizzes for user:", currentUser.email);

    const quizCollection = collection(db, "quiztest");
    const q = query(
      quizCollection,
      where("userid", "==", currentUser.email),
      orderBy("timestamp", "desc")
    );
    const querySnapshot = await getDocs(q);

    // Filter out quizzes that are not "Terminé"
    const quizzesToDelete = querySnapshot.docs.filter(doc => doc.data().status !== "Terminé");

    // Delete all quizzes that need to be removed
    for (const quizDoc of quizzesToDelete) {
      console.log("Deleting document with ID:", quizDoc.id);
      await deleteDoc(quizDoc.ref);
    }

    // Generate a new quiz
    console.log("Generating a new quiz...");

    const userData = await fetchOpenAiData(currentUser);

    let newQuizData;
    try {
      newQuizData = await generateAndStoreQuiz(userData, currentUser);
      if (typeof newQuizData === "string") {
        newQuizData = JSON.parse(newQuizData);
      }
      console.log("New quiz generated:", newQuizData);

      // Validation des données
      if (!newQuizData.l_quiz || !Array.isArray(newQuizData.l_quiz)) {
        throw new Error(
          "Le quiz généré ne contient pas de liste de questions valide."
        );
      }

      setQuizId(newQuizData.id);
      setQuestions(newQuizData.l_quiz);
      setTotalQuestions(newQuizData.l_quiz.length);
    } catch (jsonError) {
      handleJsonError(jsonError);
    }
  } catch (err) {
    console.error("Error fetching or deleting quiz data:", err);
    setError(err.message);
  } finally {
    setLoading(false);
  }
};

    
//-------------------------------------------


    fetchAndStoreQuiz();
  }, [currentUser]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = async () => {
    const currentQuestion = questions[currentQuestionIndex];
    const correctAnswer = currentQuestion.answer;
    setCorrectAnswer(correctAnswer);
    setShowAnswer(true);

    const isCorrect = selectedOption === correctAnswer;

    if (isCorrect) {
      setScore((prevScore) => prevScore + 1);
    }

    setUserAnswers((prevAnswers) => [
      ...prevAnswers,
      {
        question: currentQuestion.question,
        selectedOption,
        correctAnswer,
        isCorrect,
      },
    ]);

    // Wait for a few seconds before moving to the next question
    setTimeout(() => {
      setShowAnswer(false);
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
        setSelectedOption("");
      } else {
        setIsQuizFinished(true);
        // Update Firestore with the final score and status
        updateQuizScore();
      }
    }, 3000); // Show the correct answer for 3 seconds
  };

  const updateQuizScore = async () => {
    try {
      const quizCollection = collection(db, "quiztest");
      const q = query(
        quizCollection,
        where("userid", "==", currentUser.email),
        orderBy("timestamp", "desc"),
        limit(1)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const quizDocRef = querySnapshot.docs[0].ref;
        console.log("Updating document with ID:", quizDocRef.id);
        console.log("Document path:", quizDocRef.path);

        await updateDoc(quizDocRef, {
          score: score + 1,
          timestamp: new Date().toISOString(),
          status: "Terminé",
        });

        console.log("Document updated successfully");
      } else {
        console.error("No quiz document found to update.");
        setError("No quiz document found.");
      }
    } catch (err) {
      console.error("Error updating document:", err);
      setError(err.message);
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress size={60} />
        <Typography variant="h6" sx={{ ml: 2 }}>
          Chargement du quiz...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography color="error" variant="h6">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 600, mx: "auto", mt: 4, p: 2 }}>
      {!isQuizFinished ? (
        <>
          <LinearProgress
            variant="determinate"
            value={(currentQuestionIndex / questions.length) * 100}
          />
          <Card elevation={3} sx={{ mb: 2 }}>
            <CardContent>
              <Typography variant="h6">
                {questions[currentQuestionIndex].question}
              </Typography>
              <FormControl component="fieldset">
                <FormLabel component="legend">Options</FormLabel>
                <RadioGroup
                  value={selectedOption}
                  onChange={handleOptionChange}
                >
                  {questions[currentQuestionIndex].options.map(
                    (option, index) => (
                      <FormControlLabel
                        key={index}
                        value={option}
                        control={<Radio />}
                        label={option}
                      />
                    )
                  )}
                </RadioGroup>
              </FormControl>
              {showAnswer && (
                <Typography
                  variant="body1"
                  color={selectedOption === correctAnswer ? "green" : "red"}
                >
                  La bonne réponse est : {correctAnswer}
                </Typography>
              )}
            </CardContent>
          </Card>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!selectedOption}
          >
            Soumettre
          </Button>
          <Box mt={2}>
            <Typography variant="h5">
              Score Total : {score}/{totalQuestions}
            </Typography>
          </Box>
        </>
      ) : (
        <>
          <Typography variant="h4">Quiz terminé !</Typography>
          <Typography variant="h6">
            Votre score est : {score}/{totalQuestions}
          </Typography>
          <Box mt={2} width="100%">
            {userAnswers.map((answer, index) => (
              <Box
                key={index}
                p={2}
                mb={2}
                sx={{
                  border: "1px solid",
                  borderColor: answer.isCorrect ? "green" : "red",
                  borderRadius: "5px",
                }}
              >
                <Typography variant="h6">{answer.question}</Typography>
                <Typography
                  variant="body1"
                  color={answer.isCorrect ? "green" : "red"}
                >
                  Votre réponse : {answer.selectedOption}
                </Typography>
                {!answer.isCorrect && (
                  <Typography variant="body1" color="green">
                    Bonne réponse : {answer.correctAnswer}
                  </Typography>
                )}
              </Box>
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

export default Evaluation;

import React from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Paper,
  Alert,
  Box,
  Grid,
  Card,
  CardContent,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/system";
import GoogleIcon from "@mui/icons-material/Google";
import ProblemIcon from "@mui/icons-material/ReportProblem";
import SolutionIcon from "@mui/icons-material/EmojiObjects";
import TestimonialIcon from "@mui/icons-material/ThumbUp";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../authContext";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#f5f5f5",
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontWeight: "bold",
  fontSize: "1.5rem",
  color: "#333",
  marginBottom: theme.spacing(2),
}));

const SectionText = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  lineHeight: 1.6,
  color: "#555",
  marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
}));

const Icon = styled(GoogleIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const testimonials = [
  {
    name: "Mambou Maniolla",
    comment: "JobJourney m'a vraiment aidé à me préparer pour mes entretiens. Les simulations sont très réalistes!",
    avatar: "https://example.com/mambou-avatar.jpg",
  },
  {
    name: "Mamadou Diop",
    comment: "Grâce à JobJourney, j'ai pu identifier mes points faibles et m'améliorer rapidement.",
    avatar: "https://example.com/mamadou-avatar.jpg",
  },
  {
    name: "Fatoumata Traoré",
    comment: "Les conseils personnalisés ont fait toute la différence dans ma préparation. Merci JobJourney!",
    avatar: "https://example.com/fatoumata-avatar.jpg",
  },
  {
    name: "Oumar Diallo",
    comment: "Une plateforme incontournable pour tous ceux qui veulent se préparer efficacement aux entretiens.",
    avatar: "https://example.com/oumar-avatar.jpg",
  },
  {
    name: "Awa Ndiaye",
    comment: "Les simulations d'entretiens étaient si réalistes que je me suis sentie beaucoup plus confiante le jour J.",
    avatar: "https://example.com/awa-avatar.jpg",
  },
  {
    name: "Mohamed Sissoko",
    comment: "JobJourney m'a permis de me démarquer et d'obtenir le poste que je convoitais. Je recommande vivement!",
    avatar: "https://example.com/mohamed-avatar.jpg",
  },
];

const Connexion = () => {
  const navigate = useNavigate();
  const { signInWithEmail, signInWithGoogle } = useAuth();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(null);

  const handleEmailSignIn = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmail(email, password);
      navigate("/");
    } catch (error) {
      setError("Échec de la connexion: Email ou mot de passe incorrect.");
      console.error("Échec de la connexion", error);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
      navigate("/");
    } catch (error) {
      setError("Échec de la connexion avec Google.");
      console.error("Échec de la connexion avec Google", error);
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <Grid container spacing={3}>
        {/* Cadran de Connexion */}
        <Grid item xs={12}>
          <StyledPaper>
            <Typography component="h1" variant="h5">
              Connexion
            </Typography>
            {error && <Alert severity="error">{error}</Alert>}
            <form onSubmit={handleEmailSignIn} style={{ width: "100%" }}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Adresse e-mail"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Mot de passe"
                  type="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Box>
              <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
                <StyledButton type="submit" variant="contained" color="primary">
                  Se connecter
                </StyledButton>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  onClick={handleGoogleSignIn}
                >
                  <Icon />
                  Google
                </StyledButton>
              </Box>
            </form>
            <Typography variant="body2" align="center" style={{ marginTop: "1rem" }}>
              Vous n'avez pas de compte?{" "}
              <Link to="/inscription" style={{ textDecoration: "none" }}>
                S'inscrire ici
              </Link>
            </Typography>
          </StyledPaper>
        </Grid>

        {/* Cadran du Défi Rencontré */}
        <Grid item xs={12}>
          <StyledPaper>
            <SectionTitle>
              <ProblemIcon style={{ color: "#f44336", marginRight: "0.5rem" }} />
              Le Défi Rencontré
            </SectionTitle>
            <SectionText>
              Beaucoup de candidats se sentent mal préparés pour leurs entretiens. Le stress, l'incertitude
              sur les questions posées et l'absence de feedback rendent la préparation difficile. Cette situation
              peut entraîner une perte de confiance et réduire les chances de réussite.
            </SectionText>
          </StyledPaper>
        </Grid>

        {/* Cadran de la Solution Innovante */}
        <Grid item xs={12}>
          <StyledPaper>
            <SectionTitle>
              <SolutionIcon style={{ color: "#4caf50", marginRight: "0.5rem" }} />
              La Solution Innovante de JobJourney
            </SectionTitle>
            <SectionText>
              JobJourney transforme votre préparation aux entretiens en une expérience positive et enrichissante. 
              Avec des simulations réalistes, un feedback personnalisé et des analyses détaillées, vous gagnez en 
              confiance et en compétences pour aborder vos entretiens avec succès.
            </SectionText>
          </StyledPaper>
        </Grid>

        {/* Cadran des Témoignages Clients */}
        <Grid item xs={12}>
          <StyledPaper>
            <SectionTitle>
              <TestimonialIcon style={{ color: "#2196f3", marginRight: "0.5rem" }} />
              Nos Abonnés Témoignent
            </SectionTitle>
            <Grid container spacing={2}>
              {testimonials.map((testimonial, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Card>
                    <CardContent>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          <Avatar src={testimonial.avatar} alt={testimonial.name} />
                        </Grid>
                        <Grid item xs>
                          <Typography variant="h6">{testimonial.name}</Typography>
                          <Typography variant="body2" color="textSecondary">
                            {testimonial.comment}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </StyledPaper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Connexion;

import React from "react";
import { Box, Typography, Paper } from "@mui/material";

function PolitiqueDeConfidentialite() {
  return (
    <Box sx={{ p: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom align="center">
          Politique de confidentialité de l'application "JobJourney"
        </Typography>

        <Typography variant="h5" gutterBottom>
          Introduction
        </Typography>
        <Typography paragraph>
          La présente politique de confidentialité ("Politique") décrit comment
          l'application "JobJourney" (ci-après dénommée "nous", "notre" ou
          "l'Application") collecte, utilise, et protège vos informations
          personnelles lorsque vous utilisez notre service. En utilisant
          l'Application, vous acceptez les termes de cette Politique.
        </Typography>

        <Typography variant="h6" gutterBottom>
          1. Informations que nous collectons
        </Typography>
        <Typography paragraph>
          <strong>1.1 Informations fournies par l'utilisateur :</strong>
        </Typography>
        <Typography paragraph>
          - Inscription : Lors de votre inscription, nous collectons des
          informations telles que votre nom, adresse email et mot de passe.
        </Typography>
        <Typography paragraph>
          - Profil : Vous pouvez ajouter des informations supplémentaires à
          votre profil, telles que votre photo, votre numéro de téléphone et
          votre adresse.
        </Typography>
        <Typography paragraph>
          <strong>1.2 Informations collectées automatiquement :</strong>
        </Typography>
        <Typography paragraph>
          - Données d'utilisation : Nous collectons des informations sur votre
          utilisation de l'Application, telles que les fonctionnalités que vous
          utilisez et les pages que vous visitez.
        </Typography>
        <Typography paragraph>
          - Données techniques : Nous collectons des informations techniques
          telles que votre adresse IP, le type de navigateur, le système
          d'exploitation, et l'identifiant de l'appareil.
        </Typography>
        <Typography paragraph>
          <strong>1.3 Informations de tiers :</strong>
        </Typography>
        <Typography paragraph>
          - Si vous choisissez de vous inscrire via un compte Google, nous
          collectons des informations à partir de ce compte, conformément aux
          autorisations que vous avez accordées.
        </Typography>

        <Typography variant="h6" gutterBottom>
          2. Utilisation de vos informations
        </Typography>
        <Typography paragraph>
          <strong>2.1 Pour fournir et améliorer nos services :</strong>
        </Typography>
        <Typography paragraph>
          - Créer et gérer votre compte utilisateur.
        </Typography>
        <Typography paragraph>
          - Vous fournir un accès aux fonctionnalités de l'Application.
        </Typography>
        <Typography paragraph>
          - Personnaliser votre expérience utilisateur.
        </Typography>
        <Typography paragraph>
          - Améliorer notre Application en fonction de vos retours et
          comportements d'utilisation.
        </Typography>
        <Typography paragraph>
          <strong>2.2 Pour la communication :</strong>
        </Typography>
        <Typography paragraph>
          - Vous envoyer des notifications importantes concernant
          l'Application.
        </Typography>
        <Typography paragraph>
          - Répondre à vos questions et demandes d'assistance.
        </Typography>
        <Typography paragraph>
          <strong>2.3 Pour la sécurité et la conformité :</strong>
        </Typography>
        <Typography paragraph>
          - Protéger contre les activités frauduleuses et maintenir la sécurité
          de notre Application.
        </Typography>
        <Typography paragraph>
          - Respecter les obligations légales et réglementaires.
        </Typography>

        <Typography variant="h6" gutterBottom>
          3. Partage de vos informations
        </Typography>
        <Typography paragraph>
          <strong>3.1 Avec des fournisseurs de services tiers :</strong>
        </Typography>
        <Typography paragraph>
          - Nous pouvons partager vos informations avec des tiers qui fournissent des
          services en notre nom, tels que l'hébergement de données, l'analyse des
          performances de l'Application, et le traitement des paiements.
        </Typography>
        <Typography paragraph>
          <strong>3.2 Pour des raisons légales :</strong>
        </Typography>
        <Typography paragraph>
          - Nous pouvons divulguer vos informations si la loi l'exige, ou si nous
          croyons de bonne foi qu'une telle action est nécessaire pour se conformer à
          une obligation légale, protéger nos droits, ou prévenir des activités
          illégales.
        </Typography>

        <Typography variant="h6" gutterBottom>
          4. Sécurité de vos informations
        </Typography>
        <Typography paragraph>
          Nous mettons en œuvre des mesures de sécurité raisonnables pour protéger vos
          informations contre tout accès non autorisé, utilisation ou divulgation.
          Toutefois, aucune méthode de transmission sur Internet ou de stockage
          électronique n'est totalement sécurisée. Par conséquent, nous ne pouvons
          garantir une sécurité absolue.
        </Typography>

        <Typography variant="h6" gutterBottom>
          5. Conservation de vos informations
        </Typography>
        <Typography paragraph>
          Nous conservons vos informations personnelles aussi longtemps que nécessaire
          pour fournir nos services, répondre à nos obligations légales, résoudre les
          litiges, et faire appliquer nos accords.
        </Typography>

        <Typography variant="h6" gutterBottom>
          6. Vos droits
        </Typography>
        <Typography paragraph>
          <strong>6.1 Accès et rectification :</strong> Vous avez le droit d'accéder
          aux informations personnelles que nous détenons à votre sujet et de demander
          la correction de toute information inexacte.
        </Typography>
        <Typography paragraph>
          <strong>6.2 Suppression et opposition :</strong> Vous pouvez demander la
          suppression de vos informations personnelles ou vous opposer à leur
          traitement dans certaines circonstances.
        </Typography>
        <Typography paragraph>
          <strong>6.3 Portabilité des données :</strong> Vous avez le droit de recevoir
          les informations personnelles que vous nous avez fournies dans un format
          structuré, couramment utilisé et lisible par machine.
        </Typography>

        <Typography variant="h6" gutterBottom>
          7. Modifications de cette Politique
        </Typography>
        <Typography paragraph>
          Nous nous réservons le droit de modifier cette Politique de temps à autre.
          Nous vous informerons des modifications en publiant la nouvelle Politique
          sur l'Application. Votre utilisation continue de l'Application après de
          telles modifications constitue votre acceptation de la nouvelle Politique.
        </Typography>

        <Typography variant="h6" gutterBottom>
          8. Contact
        </Typography>
        <Typography paragraph>
          Si vous avez des questions concernant cette Politique de confidentialité,
          veuillez nous contacter à l'adresse suivante : contact@jobjourneyapp.com.
        </Typography>

        <Typography variant="body1" paragraph align="center" sx={{ mt: 4 }}>
          En utilisant l'Application, vous reconnaissez avoir lu, compris et accepté
          les termes de cette Politique de confidentialité.
        </Typography>
      </Paper>
    </Box>
  );
}

export default PolitiqueDeConfidentialite;

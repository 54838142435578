import React, { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { generateInterviewQuestionsoffre } from "../api/fetchOpenAiData";
import {
  Paper,
  Typography,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import styled from "@emotion/styled";

const StyledPaper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(3)};
  margin-top: ${(props) => props.theme.spacing(3)};
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
  box-shadow: ${(props) => props.theme.shadows[3]};
`;

const Questionnaireoffre = ({ offreId, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [generationSuccess, setGenerationSuccess] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [editedQuestions, setEditedQuestions] = useState({});

  useEffect(() => {
    // Charger les questions existantes pour l'offre en cours lorsque le composant est monté
    const loadExistingQuestions = async () => {
      if (!offreId) return;

      try {
        const offreRef = doc(db, "offres_emploi", offreId);
        const offreSnap = await getDoc(offreRef);

        if (offreSnap.exists()) {
          setQuestions(offreSnap.data().l_interview || []);
        }
      } catch (err) {
        console.error("Erreur lors du chargement des questions existantes :", err);
      }
    };

    loadExistingQuestions();
  }, [offreId]);

  const handleGenerateQuestions = async () => {
    if (!offreId) {
      console.error("Offre ID is required");
      return;
    }

    setLoading(true);
    setError(null);
    setGenerationSuccess(false);

    try {
      // Vider les questions existantes avant de générer de nouvelles questions
      const offreRef = doc(db, "offres_emploi", offreId);
      await updateDoc(offreRef, { l_interview: [] });

      const generatedQuestions = await generateInterviewQuestionsoffre(offreId);
      setQuestions(generatedQuestions || []);
      setGenerationSuccess(true);
    } catch (err) {
      setError("Échec de la génération des questions d'entretien");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleQuestionChange = (index, value) => {
    setEditedQuestions((prev) => ({
      ...prev,
      [index]: value,
    }));
  };

  const handleSaveQuestions = async () => {
    try {
      const updatedQuestions = questions.map((question, index) =>
        editedQuestions[index] !== undefined ? editedQuestions[index] : question
      );

      const offreRef = doc(db, "offres_emploi", offreId);
      await updateDoc(offreRef, { l_interview: updatedQuestions });

      setSaveSuccess(true);
      setQuestions(updatedQuestions);
      setEditedQuestions({});
    } catch (err) {
      setError("Échec de l'enregistrement des questions d'entretien");
      console.error(err);
    }
  };

  return (
    <StyledPaper>
      <Typography variant="h5" gutterBottom>
        Générer des questions d'entretien pour l'offre
      </Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={handleGenerateQuestions}
        disabled={loading}
        sx={{ marginBottom: 2 }}
      >
        {loading ? <CircularProgress size={24} /> : "Générer les Questions"}
      </Button>

      {questions.length > 0 && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Questions Interview</TableCell>
                
              </TableRow>
            </TableHead>
            <TableBody>
              {questions.map((question, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <TextField
                      fullWidth
                      value={editedQuestions[index] || question}
                      onChange={(e) =>
                        handleQuestionChange(index, e.target.value)
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {questions.length > 0 && (
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSaveQuestions}
          sx={{ marginTop: 2 }}
        >
          {loading ? <CircularProgress size={24} /> : "Enregistrer les Modifications"}
        </Button>
      )}

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
      >
        <Alert onClose={() => setError(null)} severity="error">
          {error}
        </Alert>
      </Snackbar>

      <Snackbar
        open={generationSuccess || saveSuccess}
        autoHideDuration={6000}
        onClose={() => {
          setGenerationSuccess(false);
          setSaveSuccess(false);
        }}
      >
        <Alert
          onClose={() => {
            setGenerationSuccess(false);
            setSaveSuccess(false);
          }}
          severity="success"
        >
          {generationSuccess
            ? "Questions générées avec succès !"
            : "Questions enregistrées avec succès !"}
        </Alert>
      </Snackbar>

      <Button
        variant="outlined"
        color="secondary"
        onClick={onClose}
        sx={{ marginTop: 2 }}
      >
        Fermer
      </Button>
    </StyledPaper>
  );
};

export default Questionnaireoffre;

import React, { useContext, useState, useEffect, createContext } from "react";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  signInWithPopup,
  getAuth,
} from "firebase/auth";
import { db, googleProvider } from "./firebaseConfig";
import { doc, setDoc, getDoc } from "firebase/firestore";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const auth = getAuth();

  const signInWithEmail = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const signUpWithEmail = (email, password, additionalInfo) => {
    return createUserWithEmailAndPassword(auth, email, password).then(
      async (userCredential) => {
        const user = userCredential.user;
        const userRef = doc(db, "Users", user.email);
        await setDoc(userRef, {
          email: user.email,
          createdAt: new Date(),
          userID: user.email,
          status: "Active",
          category: "User",
          subscription: {
            plan: "Free",
            endDate: null, // You can set this to a specific date if needed
            cpt_evaluation: 0,
          },
        });
        return user;
      }
    );
  };

  const signInWithGoogle = () => {
    return signInWithPopup(auth, googleProvider).then(async (result) => {
      const user = result.user;
      const userRef = doc(db, "Users", user.email);
      const docSnap = await getDoc(userRef);
      if (!docSnap.exists()) {
        await setDoc(userRef, {
          email: user.email,
          createdAt: new Date(),
          userID: user.email,
          status: "Active",
          category: "User",
          subscription: {
            plan: "Free",
            endDate: null,
            cpt_evaluation: 0,
          },
        });
      } else {
        // If the user already exists but subscription plan doesn't exist, update it
        const userData = docSnap.data();
        if (!userData.subscription || !userData.subscription.plan) {
          await setDoc(
            userRef,
            {
              ...userData,
              subscription: {
                plan: "Free",
                endDate: userData.subscription?.endDate || null,
                cpt_evaluation: userData.subscription?.cpt_evaluation || 0,
              },
            },
            { merge: true }
          );
        }
      }
      return user;
    });
  };

  const logout = () => {
    return signOut(auth);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });

    return unsubscribe;
  }, [auth]);

  const value = {
    currentUser,
    signInWithEmail,
    signUpWithEmail,
    signInWithGoogle,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
 
import React from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Avatar,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import {
  DirectionsRun as DirectionsRunIcon,
  Chat as ChatIcon,
  Assessment as AssessmentIcon,
  CheckCircle as CheckCircleIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import "./Home.css"; // Import du fichier CSS

function Home() {
  const navigate = useNavigate();

  const handleSubscriptionClick = () => {
    navigate("/subscriptionplans");
  };

  const testimonials = [
    {
      name: "Mambou Maniolla",
      comment: "JobJourney m'a vraiment aidé à me préparer pour mes entretiens. Les simulations sont très réalistes!",
      avatar: "https://example.com/aicha-avatar.jpg",
    },
    {
      name: "Mamadou Diop",
      comment: "Grâce à JobJourney, j'ai pu identifier mes points faibles et m'améliorer rapidement.",
      avatar: "https://example.com/mamadou-avatar.jpg",
    },
    {
      name: "Konate",
      comment: "Les feedbacks personnalisés sont excellents. Je recommande vivement cette application!",
      avatar: "https://example.com/fatoumata-avatar.jpg",
    },
    {
      name: "Chaboui Dieudonné",
      comment: "Les analyses détaillées m'ont aidé à comprendre où je pouvais m'améliorer.",
      avatar: "https://example.com/ibrahima-avatar.jpg",
    },
    {
      name: "Aminata Diallo",
      comment: "Une application très intuitive qui m'a permis de me sentir prête pour mes entretiens.",
      avatar: "https://example.com/aminata-avatar.jpg",
    },
    {
      name: "Cheikh Ndiaye",
      comment: "Le feedback reçu a été extrêmement utile pour mes entretiens réels.",
      avatar: "https://example.com/cheikh-avatar.jpg",
    },
  ];

  return (
    <div>
      <Typography variant="h3" gutterBottom>
        Bienvenue sur JobJourney!
      </Typography>
      <Typography variant="body1" gutterBottom>
        L'intelligence artificielle dédiée à vous aider dans votre parcours professionnel. Elle vous offre plusieurs fonctionnalités essentielles :
      </Typography>
      <List>
        <ListItem>
          <ListItemIcon>
            <DirectionsRunIcon />
          </ListItemIcon>
          <ListItemText primary="Préparation aux entretiens" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <ChatIcon />
          </ListItemIcon>
          <ListItemText primary="Simulations d'entretiens" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <AssessmentIcon />
          </ListItemIcon>
          <ListItemText primary="Analyses détaillées des réponses" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CheckCircleIcon />
          </ListItemIcon>
          <ListItemText primary="Feedback personnalisé et rapports" />
        </ListItem>
      </List>

      <div className="video-container">
        <iframe
          src="https://www.youtube.com/embed/2nSsUllWuoM"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      <div className="testimonials">
        <Typography variant="h4" gutterBottom>
          Ce que disent nos Abonnés
        </Typography>
        <Grid container spacing={4}>
          {testimonials.map((testimonial, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Card>
                <CardContent>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Avatar src={testimonial.avatar} alt={testimonial.name} />
                    </Grid>
                    <Grid item xs>
                      <Typography variant="h6">{testimonial.name}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        {testimonial.comment}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}

export default Home;

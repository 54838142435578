import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Box,
  Paper,
  Grid,
  Button,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import HistoryIcon from "@mui/icons-material/History";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import {
  generateAndStoreSimulations,
  fetchOpenAiData,
} from "../api/fetchOpenAiData";
import { getAuth } from "firebase/auth";
import {
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
  getDoc,
  Timestamp,
} from "firebase/firestore";
import { db } from "../firebaseConfig";
import { fetchUserSubscriptions } from "../fetchUserSubscriptions"; // Assurez-vous que le chemin est correct

function Simulations() {
  const navigate = useNavigate();
  const [simulation, setSimulation] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pastSimulations, setPastSimulations] = useState([]);
  const [isFreePlan, setIsFreePlan] = useState(false); // État pour suivre si le plan est gratuit
  const [openDialog, setOpenDialog] = useState(false);

  const checkCareerGoals = async () => {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (currentUser) {
      const userDoc = doc(db, "Users", currentUser.email);
      const userSnapshot = await getDoc(userDoc);
      if (userSnapshot.exists()) {
        const userData = userSnapshot.data();
        if (!userData.careerGoals) {
          setOpenDialog(true);
          return false;
        }
      } else {
        setOpenDialog(true);
        return false;
      }
    }
    return true;
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    navigate("/profile");
  };

  const checkExistingGeneratedSimulation = async (userId) => {
    const simulationsCollection = collection(db, "simulations");
    const q = query(
      simulationsCollection,
      where("userid", "==", userId),
      where("status", "==", "Généré")
    );
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };

  const deleteInProgressSimulations = async (userId) => {
    const simulationsCollection = collection(db, "simulations");
    const q = query(
      simulationsCollection,
      where("userid", "==", userId),
      where("status", "==", "Encours")
    );
    const querySnapshot = await getDocs(q);
    const deletePromises = querySnapshot.docs.map((docSnapshot) =>
      deleteDoc(doc(db, "simulations", docSnapshot.id))
    );
    await Promise.all(deletePromises);
  };

  const handleGenerateSimulation = async () => {
    const hasCareerGoals = await checkCareerGoals();
    if (!hasCareerGoals) {
      return;
    }

    setError(null);
    setLoading(true);
    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;

      if (!currentUser) {
        throw new Error("Utilisateur non connecté");
      }

      // Supprimer les simulations en cours avant de vérifier les simulations générées
      await deleteInProgressSimulations(currentUser.email);

      const existingSimulation = await checkExistingGeneratedSimulation(
        currentUser.email
      );

      if (existingSimulation) {
        setLoading(false);
        return; // Si une simulation avec le statut "Généré" existe, ne rien faire
      }

      const userData = await fetchOpenAiData(currentUser);

      if (!userData) {
        throw new Error("Données utilisateur introuvables");
      }

      const simulationData = await generateAndStoreSimulations(
        userData,
        currentUser
      );
      setSimulation(simulationData);
      navigate("/simulationinterview");
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleViewResources = () => {
    navigate("/guidesressource");
  };

  const fetchPastSimulations = async (userId) => {
    const simulationsCollection = collection(db, "simulations");
    const q = query(
      simulationsCollection,
      where("userid", "==", userId),
      where("status", "==", "Terminé")
    );
    const querySnapshot = await getDocs(q);
    const simulationsData = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        id: doc.id,
        ...data,
        timestamp:
          data.timestamp instanceof Timestamp
            ? data.timestamp
            : Timestamp.fromDate(new Date(data.timestamp)),
      };
    });

    // Trier les simulations par timestamp décroissant
    const sortedSimulations = simulationsData.sort(
      (a, b) => b.timestamp.seconds - a.timestamp.seconds
    );

    setPastSimulations(sortedSimulations);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const subscriptions = await fetchUserSubscriptions(() => {});
        if (subscriptions.length > 0) {
          const user = subscriptions[0];
          setIsFreePlan(user.name === "Free");
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des informations d'abonnement :",
          error
        );
      }
    };

    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (currentUser) {
      fetchPastSimulations(currentUser.email);
      fetchUserData();
    }
  }, []);

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Simulations
      </Typography>
      <Typography variant="body1" gutterBottom>
        Pratiquez vos compétences en entretien avec des simulations
        interactives. Vous pouvez commencer une nouvelle simulation ou revoir
        vos performances passées.
      </Typography>

      <Grid container spacing={3}>
        {/* Section: Nouvelle Simulation */}
        <Grid item xs={12} md={6}>
          <Paper
            sx={{
              p: 2,
              textAlign: "center",
              transition: "transform 0.3s, box-shadow 0.3s",
              "&:hover": { transform: "scale(1.05)", boxShadow: 6 },
            }}
            elevation={3}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <PlayCircleFilledIcon
                sx={{ fontSize: 50, color: "primary.main", mb: 1 }}
              />
              <Typography variant="h6" gutterBottom>
                Nouvelle Simulation
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleGenerateSimulation}
                disabled={loading || isFreePlan} // Désactiver le bouton si le plan est gratuit
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Démarrer une Simulation"
                )}
              </Button>
            </Box>
          </Paper>
        </Grid>

        {/* Section: Simulations Passées */}
        <Grid item xs={12} md={6}>
          <Paper
            sx={{
              p: 2,
              transition: "transform 0.3s, box-shadow 0.3s",
              "&:hover": { transform: "scale(1.05)", boxShadow: 6 },
            }}
            elevation={3}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <HistoryIcon
                sx={{ fontSize: 50, color: "secondary.main", mb: 1 }}
              />
              <Typography variant="h6" gutterBottom>
                Simulations Passées
              </Typography>
              <List sx={{ width: "100%", maxHeight: 200, overflow: "auto" }}>
                {pastSimulations.map((simulation, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={`Date: ${simulation.timestamp
                        .toDate()
                        .toLocaleDateString()} - Résultat: ${
                        simulation.Resultat
                      }`}
                      secondary={`Note: ${simulation.globalScore} - Feedback: ${simulation.pointsaameliorer}`}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Paper>
        </Grid>

        {/* Section: Ressources d'Entraînement */}
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              transition: "transform 0.3s, box-shadow 0.3s",
              "&:hover": { transform: "scale(1.05)", boxShadow: 6 },
            }}
            elevation={3}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <MenuBookIcon
                sx={{ fontSize: 50, color: "action.active", mb: 1 }}
              />
              <Typography variant="h6" gutterBottom>
                Ressources d'Entraînement
              </Typography>
              <Typography variant="body1" sx={{ textAlign: "center" }}>
                Accédez à des vidéos, des articles et d'autres ressources pour
                améliorer vos compétences en entretien.
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                sx={{ mt: 2 }}
                onClick={handleViewResources}
              >
                Voir les Ressources
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {error && (
        <Box sx={{ color: "red", mt: 2 }}>
          <Typography variant="body1">{error}</Typography>
        </Box>
      )}

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Objectifs de Carrière Manquants</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Veuillez définir vos objectifs de carrière dans votre profil avant de commencer une simulation.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Aller au Profil
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Simulations;

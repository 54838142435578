import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Grid,
  Snackbar,
  Alert,
  Switch,  // Ajout du Switch pour le toggle
  FormControlLabel
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { db } from "../firebaseConfig";
import { doc, setDoc } from "firebase/firestore";
import { useAuth } from "../authContext";

function OffreForm({ selectedOffre, onSave, onCancel }) {
  const { currentUser } = useAuth(); // Get the current user from your auth context
  const [offre, setOffre] = useState({
    titre: "",
    type: "",
    lieu: "",
    datePublication: "",
    dateEntretien: "",
    description: "",
    active: true, // Ajout du champ `active` avec une valeur par défaut
    offre_id: uuidv4(),
    ownerid: currentUser ? currentUser.email : "", // Set the ownerid to the current user's email
  });

  const [error, setError] = useState(""); // State for error message

  useEffect(() => {
    if (selectedOffre) {
      setOffre({
        ...selectedOffre,
        datePublication: selectedOffre.datePublication.toDate().toISOString().split("T")[0],
        dateEntretien: selectedOffre.dateEntretien.toDate().toISOString().split("T")[0],
        active: selectedOffre.active, // Prendre en compte l'état `active`
        offre_id: selectedOffre.offre_id, // Ensure offre_id is correctly set
        ownerid: selectedOffre.ownerid, // Maintain the ownerid from the selected offer
      });
    }
  }, [selectedOffre]);

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setOffre({
      ...offre,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const pubDate = new Date(offre.datePublication);
    const entDate = new Date(offre.dateEntretien);

    if (entDate <= pubDate) {
      setError("La date d'entretien doit être supérieure à la date de publication.");
      return;
    }

    try {
      if (!offre.offre_id) {
        throw new Error("L'ID de l'offre est invalide.");
      }

      const offreRef = doc(db, "offres_emploi", offre.offre_id);
      await setDoc(offreRef, {
        ...offre,
        datePublication: pubDate,
        dateEntretien: entDate,
        ownerid: currentUser.email,
      });
      onSave();
    } catch (error) {
      console.error("Erreur lors de l'enregistrement de l'offre :", error);
      setError("Erreur lors de l'enregistrement de l'offre.");
    }
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        maxWidth: 800,
        margin: "auto",
        padding: 4,
        borderRadius: 3,
        boxShadow: 3,
        bgcolor: "background.paper",
      }}
      onSubmit={handleSubmit}
    >
      <Typography variant="h5" gutterBottom>
        {selectedOffre ? "Modifier l'offre" : "Ajouter une offre"}
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            label="Titre"
            name="titre"
            value={offre.titre}
            onChange={handleChange}
            required
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth required>
            <InputLabel>Type</InputLabel>
            <Select
              name="type"
              value={offre.type}
              onChange={handleChange}
              variant="outlined"
            >
              <MenuItem value="Temps Plein">Temps Plein</MenuItem>
              <MenuItem value="Temps Partiel">Temps Partiel</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Lieu"
            name="lieu"
            value={offre.lieu}
            onChange={handleChange}
            required
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Date de Publication"
            name="datePublication"
            type="date"
            value={offre.datePublication}
            onChange={handleChange}
            required
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Date d'Entretien"
            name="dateEntretien"
            type="date"
            value={offre.dateEntretien}
            onChange={handleChange}
            required
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Description"
            name="description"
            value={offre.description}
            onChange={handleChange}
            required
            multiline
            rows={4}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={offre.active}
                onChange={handleChange}
                name="active"
                color="primary"
              />
            }
            label="Offre Active"
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
        <Button variant="contained" color="primary" type="submit">
          {selectedOffre ? "Mettre à jour" : "Ajouter"}
        </Button>
        <Button variant="outlined" color="secondary" onClick={onCancel}>
          Annuler
        </Button>
      </Box>
      {error && (
        <Snackbar open={Boolean(error)} autoHideDuration={6000} onClose={() => setError("")}>
          <Alert onClose={() => setError("")} severity="error">
            {error}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
}

export default OffreForm;

import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  CircularProgress,
  Card,
  CardContent,
  CardActions,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import BookIcon from "@mui/icons-material/Book";
import { useAuth } from "../authContext";
import { db } from "../firebaseConfig";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
} from "firebase/firestore";
import {
  fetchOpenAiData,
  generateAndStoreGuides,
} from "../api/fetchOpenAiData";
import { useNavigate } from "react-router-dom";

const GuidesRessource = () => {
  const { currentUser } = useAuth();
  const [guides, setGuides] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkUserProfile = async () => {
      if (!currentUser) return;

      const userDoc = doc(db, "Users", currentUser.email);
      const userSnapshot = await getDoc(userDoc);
      if (userSnapshot.exists()) {
        const userData = userSnapshot.data();
        if (
          !userData.skills ||
          !userData.interestSectors ||
          !userData.careerGoals
        ) {
          setOpenDialog(true);
          return false;
        }
      } else {
        setOpenDialog(true);
        return false;
      }
      return true;
    };

    const fetchGuides = async () => {
      if (!currentUser) {
        setLoading(false);
        return;
      }

      try {
        const guidesCollection = collection(db, "guides");
        const q = query(
          guidesCollection,
          where("userId", "==", currentUser.email)
        );
        const querySnapshot = await getDocs(q);
        const guidesData = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            userId: data.userId,
            l_guide: data.l_guide,
          };
        });
        setGuides(guidesData);
      } catch (err) {
        if (err.message.includes("parsing JSON")) {
          setError(
            "La création des guides a été interrompue. Merci de tenter à nouveau."
          );
          navigate("/preparation");
        } else {
          setError(err.message);
        }
      } finally {
        setLoading(false);
      }
    };

    const initializeGuides = async () => {
      if (!currentUser) return;

      try {
        const guidesCollection = collection(db, "guides");
        const q = query(
          guidesCollection,
          where("userId", "==", currentUser.email)
        );
        const querySnapshot = await getDocs(q);
        const guidesCount = querySnapshot.size;

        if (guidesCount > 10) {
          fetchGuides();
          return;
        }

        const userData = await fetchOpenAiData(currentUser);
        await generateAndStoreGuides(userData, currentUser);
        fetchGuides();
      } catch (error) {
        if (error.message.includes("parsing JSON")) {
          setError(
            "La création des guides a été interrompue. Merci de tenter à nouveau."
          );
          navigate("/preparation");
        } else {
          setError(error.message);
        }
      }
    };

    const initializeComponent = async () => {
      const isUserProfileComplete = await checkUserProfile();
      if (isUserProfileComplete) {
        initializeGuides();
      } else {
        setLoading(false);
      }
    };

    initializeComponent();
  }, [currentUser]);

  const handleCloseDialog = () => {
    navigate("/profile");
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress size={60} />
        <Typography variant="h6" sx={{ ml: 2 }}>
          Chargement des guides...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography color="error" variant="h6">
          Erreur de chargement : {error}
        </Typography>
      </Box>
    );
  }

  const cardColors = ["#FFCDD2", "#C8E6C9", "#BBDEFB"];

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
        <BookIcon sx={{ mr: 1 }} />
        <Typography variant="h4" gutterBottom>
          Guides et Ressources
        </Typography>
      </Box>
      {guides.length > 0 ? (
        <Box>
          {guides.map((guide, index) => (
            <Card
              key={guide.id}
              sx={{
                mb: 2,
                backgroundColor: cardColors[index % cardColors.length],
                transition: "transform 0.3s",
                "&:hover": { transform: "scale(1.05)" },
              }}
              elevation={3}
            >
              <CardContent>
                {guide.l_guide.slice(0, 3).map((item, index) => (
                  <Box key={index} sx={{ mb: 2 }}>
                    <Typography variant="h6">{item.title}</Typography>
                    <Typography variant="body2">{item.description}</Typography>
                  </Box>
                ))}
              </CardContent>
              {guide.link && (
                <CardActions>
                  <Button
                    size="small"
                    color="primary"
                    href={guide.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Lire plus
                  </Button>
                </CardActions>
              )}
            </Card>
          ))}
        </Box>
      ) : (
        <Typography variant="body1">Aucun guide trouvé.</Typography>
      )}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Profil Incomplet"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Veuillez renseigner vos informations de carrière dans le menu
            profil.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default GuidesRessource;

import React from "react";

const OfflineNotification = () => (
  <div style={styles.notification}>
    Vous êtes hors ligne. Veuillez vérifier votre connexion Internet.
  </div>
);

const styles = {
  notification: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: "red",
    color: "white",
    textAlign: "center",
    padding: "10px",
    zIndex: 1000,
  },
};

export default OfflineNotification;

import React, { useState } from "react";
import { useAuth } from "../authContext";
import {
  Container,
  TextField,
  Button,
  Typography,
  Paper,
  Link,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";

// Créer des composants stylisés avec `styled`
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const Form = styled("form")(({ theme }) => ({
  width: "100%",
  marginTop: theme.spacing(1),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const LoadingIndicator = styled(CircularProgress)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const LinkText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const Inscription = () => {
  const { signUpWithEmail } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const user = await signUpWithEmail(email, password);
      if (user && user.email) {
        console.log("User signed up successfully", user.email);
        alert(
          "Veuillez renseigner toutes les informations de votre profil, afin que l'IA personnalise votre parcours"
        );
        navigate("/profile");
      } else {
        throw new Error("User signup failed or email is undefined");
      }
    } catch (error) {
      console.error("Sign up failed:", error);
      setError(error.message);
    }
    setLoading(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <StyledPaper>
        <Typography component="h1" variant="h5">
          Inscription
        </Typography>
        <Form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Adresse e-mail"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Mot de passe"
            type="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <SubmitButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
          >
            S'inscrire
          </SubmitButton>
          {loading && <LoadingIndicator />}
          {error && (
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          )}
          <LinkText variant="body2">
            Vous avez déjà un compte?{" "}
            <Link
              onClick={() => navigate("/connexion")}
              style={{ cursor: "pointer" }}
            >
              Connectez-vous ici
            </Link>
          </LinkText>
        </Form>
      </StyledPaper>
    </Container>
  );
};

export default Inscription;

import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonIcon from "@mui/icons-material/Person";
import DescriptionIcon from "@mui/icons-material/Description";
import { styled } from "@mui/system";
import { collection, getDocs, doc, deleteDoc, query, where,Timestamp  } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { useAuth } from "../authContext";
import OffreForm from "./OffreForm";
import Candidature from "./Candidature";  // Assurez-vous d'importer le composant Candidature
import Questionnaireoffre from "./Questionnaireoffre"; // Import du composant Questionnaireoffre

// Styles pour le tableau
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  maxHeight: 440,
}));

// Composant OffreEmploi
function OffreEmploi() {
  const { currentUser } = useAuth(); // Get the current user from your auth context
  const [offres, setOffres] = useState([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [selectedOffre, setSelectedOffre] = useState(null);
  const [isCandidatureOpen, setIsCandidatureOpen] = useState(false);  // Nouveau état pour la Candidature
  const [isQuestionnaireOpen, setIsQuestionnaireOpen] = useState(false);  // Nouveau état pour le Questionnaire
  const [selectedOffreId, setSelectedOffreId] = useState(null);  // ID de l'offre sélectionnée
  const [dialogOpen, setDialogOpen] = useState(false);  // Etat pour ouvrir ou fermer le dialog
  const [offreToDelete, setOffreToDelete] = useState(null);  // Stocker l'ID de l'offre à supprimer

  // Fonction pour récupérer les offres d'emploi depuis Firestore
  const fetchOffres = async () => {
    if (!currentUser) return;

    try {
      const offresCollection = collection(db, "offres_emploi");
      const offresQuery = query(
        offresCollection,
        where("ownerid", "==", currentUser.email) // Filter by ownerid matching the current user's email
      );
      const offresSnapshot = await getDocs(offresQuery);
      const offresList = offresSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setOffres(offresList);
    } catch (error) {
      console.error("Erreur lors de la récupération des offres d'emploi :", error);
    }
  };

  useEffect(() => {
    fetchOffres();
  }, [currentUser]);

  const handleAjouterOffre = () => {
    setSelectedOffre(null);
    setIsFormOpen(true);
  };

  const handleModifierOffre = (id) => {
    const offre = offres.find((offre) => offre.id === id);
    setSelectedOffre(offre);
    setIsFormOpen(true);
  };

  const handleFormSave = () => {
    setIsFormOpen(false);
    fetchOffres();  // Recharger les offres après l'ajout ou la modification
  };

  const handleFormCancel = () => {
    setIsFormOpen(false);
  };

  const handleSupprimerOffre = async () => {
    try {
      const offreRef = doc(db, "offres_emploi", offreToDelete);
      await deleteDoc(offreRef);
      fetchOffres();  // Recharger les offres après la suppression
      setDialogOpen(false);
    } catch (error) {
      console.error("Erreur lors de la suppression de l'offre :", error);
    }
  };

  const handleDeleteClick = (id) => {
    setOffreToDelete(id);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setOffreToDelete(null);
  };

  const handleAfficherCandidats = (id) => {
    setSelectedOffreId(id);
    setIsCandidatureOpen(true);  // Ouvrir le composant Candidature
  };

  const handleGenererQuestionnaire = (id) => {
    setSelectedOffreId(id);
    setIsQuestionnaireOpen(true);  // Ouvrir le composant Questionnaireoffre
  };

  const handleCandidatureClose = () => {
    setIsCandidatureOpen(false);
    setSelectedOffreId(null);  // Réinitialiser l'ID de l'offre sélectionnée
  };

  const handleQuestionnaireClose = () => {
    setIsQuestionnaireOpen(false);
    setSelectedOffreId(null);  // Réinitialiser l'ID de l'offre sélectionnée
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Offres d'Emploi
      </Typography>
      {!isFormOpen && !isCandidatureOpen && !isQuestionnaireOpen ? (
        <>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAjouterOffre}
            sx={{ mb: 2 }}
          >
            Ajouter Offre
          </Button>
          <StyledTableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Titre</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Lieu</TableCell>
                  <TableCell>Date de Publication</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {offres.map((offre) => (
                  <TableRow key={offre.id}>
                    <TableCell>{offre.titre}</TableCell>
                    <TableCell>{offre.type}</TableCell>
                    <TableCell>{offre.lieu}</TableCell>
                    <TableCell>{new Timestamp(offre.datePublication.seconds, offre.datePublication.nanoseconds).toDate().toLocaleDateString()}</TableCell>
                    <TableCell>
                      <IconButton
                        color="primary"
                        onClick={() => handleModifierOffre(offre.id)}
                        aria-label="Modifier"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => handleDeleteClick(offre.id)}
                        aria-label="Supprimer"
                      >
                        <DeleteIcon />
                      </IconButton>
                      <IconButton
                        color="primary"
                        onClick={() => handleAfficherCandidats(offre.id)}
                        aria-label="Candidats"
                      >
                        <PersonIcon />
                      </IconButton>
                      <IconButton
                        color="primary"
                        onClick={() => handleGenererQuestionnaire(offre.id)}
                        aria-label="Questionnaire"
                      >
                        <DescriptionIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </>
      ) : isFormOpen ? (
        <OffreForm
          selectedOffre={selectedOffre}
          onSave={handleFormSave}
          onCancel={handleFormCancel}
        />
      ) : isCandidatureOpen ? (
        <Candidature
          offreId={selectedOffreId}
          onClose={handleCandidatureClose}
        />
      ) : (
        <Questionnaireoffre
          offreId={selectedOffreId}
          onClose={handleQuestionnaireClose}
        />
      )}

      {/* Dialog de confirmation */}
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Confirmer la suppression</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Êtes-vous sûr de vouloir supprimer cette offre d'emploi ? Cette action est irréversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Annuler
          </Button>
          <Button onClick={handleSupprimerOffre} color="error">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default OffreEmploi;

import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  TextField,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { styled } from "@mui/system";
import { db } from "../firebaseConfig"; // Assurez-vous d'avoir configuré Firebase
import { collection, addDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const MainContent = styled('div')({
  padding: '16px',
  marginTop: '64px',
});

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false); // État pour ouvrir/fermer le dialogue
  const navigate = useNavigate(); // Créez la fonction de navigation

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const docRef = await addDoc(collection(db, "contacts"), {
        name,
        email,
        message,
        timestamp: new Date(),
      });
      console.log("Document written with ID: ", docRef.id);
      setName("");
      setEmail("");
      setMessage("");
      setDialogOpen(true); // Ouvrir le dialogue après la soumission réussie
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    navigate("/dashboard"); // Rediriger vers le tableau de bord lorsque le dialogue est fermé
  };

  return (
    <div>
      <AppBar position="fixed">
        <Toolbar>
          <Typography variant="h6">
            Contactez-nous
          </Typography>
        </Toolbar>
      </AppBar>
      <MainContent>
        <Container maxWidth="sm">
          <Typography variant="h4" gutterBottom>
            Contactez-nous
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Nom"
              fullWidth
              margin="normal"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <TextField
              label="Email"
              type="email"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <TextField
              label="Message"
              fullWidth
              margin="normal"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              multiline
              rows={4}
              required
            />
            <Button variant="contained" color="primary" type="submit">
              Envoyer
            </Button>
          </form>
          {/* Boîte de dialogue pour le message de succès */}
          <Dialog open={dialogOpen} onClose={handleCloseDialog}>
            <DialogTitle>Message envoyé</DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                Merci pour votre message. Nous vous répondrons dès que possible.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </MainContent>
    </div>
  );
};

export default Contact;

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../firebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import { Container, Typography, List, ListItem, Divider, Box, Grid, Button } from "@mui/material";
import styled from "@emotion/styled";

const StyledPaper = styled(Container)`
  padding: 24px;
  margin-top: 24px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #fff;
`;

const SectionTitle = styled(Typography)`
  font-weight: bold;
  margin-bottom: 8px;
  color: #3f51b5;
`;

const ContentText = styled(Typography)`
  margin-bottom: 16px;
  font-size: 16px;
`;

const ExperienceItem = styled(Box)`
  margin-bottom: 16px;
`;

const CVCandidat = () => {
  const { candidateEmail } = useParams();
  const navigate = useNavigate();
  const [candidateData, setCandidateData] = useState(null);

  useEffect(() => {
    const fetchCandidateData = async () => {
      if (candidateEmail) {
        try {
          const docRef = doc(db, "Users", candidateEmail);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setCandidateData(docSnap.data());
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error fetching candidate data: ", error);
        }
      }
    };

    fetchCandidateData();
  }, [candidateEmail]);

  if (!candidateData) {
    return <Typography>Chargement du CV...</Typography>;
  }

  return (
    <StyledPaper>
      <Button variant="outlined" onClick={() => navigate(-1)} style={{ marginBottom: "16px" }}>
        Retour
      </Button>

      <Typography variant="h4" gutterBottom>
        {candidateData.name || "Nom du Candidat"}
      </Typography>
      <Divider />

      <SectionTitle variant="h6">Objectifs de carrière</SectionTitle>
      <ContentText>{candidateData.careerGoals}</ContentText>

      <SectionTitle variant="h6">Secteurs d'intérêt</SectionTitle>
      <ContentText>{candidateData.interestSectors?.join(", ")}</ContentText>

      <SectionTitle variant="h6">Compétences</SectionTitle>
      <ContentText>{candidateData.skills?.join(", ")}</ContentText>

      <SectionTitle variant="h6">Expériences professionnelles</SectionTitle>
      <Grid container spacing={2}>
        {candidateData.experiences?.map((exp, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <ExperienceItem>
              <Typography variant="body1" fontWeight="bold">
                {exp.position}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {exp.company}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {exp.duration}
              </Typography>
            </ExperienceItem>
          </Grid>
        ))}
      </Grid>
    </StyledPaper>
  );
};

export default CVCandidat;

// src/App.js
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { AuthProvider, useAuth } from "./authContext";
import Home from "./component/home";
import Connexion from "./component/Connexion";
import GuidesRessource from "./component/guidesressource";
import InterviewQuestions from "./component/interviewquestions";
import Evaluation from "./component/evaluation";
import Inscription from "./component/inscription";
import Dashboard from "./component/dashboard";
import Preparation from "./component/preparation";
import Simulations from "./component/simulations";
import SimulationInterview from "./component/simulationinterview";
import Analysis from "./component/analysis";
import Profile from "./component/profile";
import FeedbackSummary from "./component/feedbacksummary";
import SubscriptionPlans from "./component/subscriptionplans";
import OfflineNotification from "./component/OfflineNotification";
import Test2 from "./component/test2";
import CheckoutSingleSubscription from "./component/CheckoutSingleSubscription";
import { Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Footer from "./component/footer";
import NavBar from "./component/navBar"; 
import InterviewCandidat from "./component/InterviewCandidat"; 
import DashboardAdmin from "./backofficeadmin/dashboardadmin";
import UserManagement from "./backofficeadmin/UserManagement";
import Contact from "./component/contact";
import Candidature from "./component/Candidature";
import OffreEmploi from "./component/OffreEmploi";
import Questionnaireoffre from "./component/Questionnaireoffre";
import InterviewReport from "./component/InterviewReport";
import CVCandidat from "./component/CVCandidat";
import DashboardEntreprise from "./component/DashboardEntreprise";
import ConditionsPdfViewer from "./component/ConditionsPdfViewer";
import PolitiqueDeConfidentialite from "./component/PolitiqueDeConfidentialite";


const theme = createTheme();

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  main: {
    marginTop: "20px",
  },
});

const PrivateRoute = ({ children }) => {
  const { currentUser } = useAuth();
  return currentUser ? children : <Navigate to="/connexion" />;
};

function App() {
  const classes = useStyles();
  const [isOffline, setIsOffline] = useState(!navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOffline(false);
    const handleOffline = () => setIsOffline(true);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Router>
          <div className={classes.root}>
            {isOffline && <OfflineNotification />}
            <NavBar />
            <Container className={classes.main}>
              <Routes>
                <Route path="/connexion" element={<Connexion />} />
                <Route path="/inscription" element={<Inscription />} />
                <Route
                  path="/"
                  element={
                    <PrivateRoute>
                      <Home />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <PrivateRoute>
                      <Dashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/preparation"
                  element={
                    <PrivateRoute>
                      <Preparation />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/simulations"
                  element={
                    <PrivateRoute>
                      <Simulations />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/analysis"
                  element={
                    <PrivateRoute>
                      <Analysis />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <PrivateRoute>
                      <Profile />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/subscriptionplans"
                  element={
                    <PrivateRoute>
                      <SubscriptionPlans />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/evaluation"
                  element={
                    <PrivateRoute>
                      <Evaluation />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/guidesressource"
                  element={
                    <PrivateRoute>
                      <GuidesRessource />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/interviewquestions"
                  element={
                    <PrivateRoute>
                      <InterviewQuestions />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/simulationinterview"
                  element={
                    <PrivateRoute>
                      <SimulationInterview />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/feedbacksummary"
                  element={
                    <PrivateRoute>
                      <FeedbackSummary />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/CheckoutSingleSubscription"
                  element={
                    <PrivateRoute>
                      <CheckoutSingleSubscription />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/PaymentProvider"
                  element={
                    <PrivateRoute>
                      <Test2 />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/test2"
                  element={
                    <PrivateRoute>
                      <Test2 />
                    </PrivateRoute>
                  }
                />
                  
                  <Route
                  path="/InterviewCandidat"
                  element={
                    <PrivateRoute>
                      <InterviewCandidat />
                    </PrivateRoute>
                  }
                />

               <Route
                  path="/OffreEmploi"
                  element={
                    <PrivateRoute>
                      <OffreEmploi/>
                    </PrivateRoute>
                  }
                />

               <Route
                  path="/Questionnaireoffre"
                  element={
                    <PrivateRoute>
                      <Questionnaireoffre/>
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/dashboardadmin"
                  element={
                    <PrivateRoute>
                      <DashboardAdmin />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/UserManagement"
                  element={
                    <PrivateRoute>
                      <UserManagement />
                    </PrivateRoute>
                  }
                />
                 <Route
                  path="/contact"
                  element={
                    <PrivateRoute>
                      <Contact />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/Candidature"
                  element={
                    <PrivateRoute>
                      <Candidature />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/InterviewReport"
                  element={
                    <PrivateRoute>
                      <InterviewReport />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/DashboardEntreprise"
                  element={
                    <PrivateRoute>
                      <DashboardEntreprise />
                    </PrivateRoute>
                  }
                />
                <Route path="/" element={<Dashboard />} />
                <Route path="/InterviewCandidat/:offerId" element={<InterviewCandidat />} />
                <Route path="/CVCandidat/:candidateEmail" element={<CVCandidat />} />
                  <Route
                  path="/ConditionsPdfViewer"
                  element={
                    <PrivateRoute>
                      <ConditionsPdfViewer />
                    </PrivateRoute>
                  }
                />
                 <Route
                  path="/PolitiqueDeConfidentialite"
                  element={
                    <PrivateRoute>
                      <PolitiqueDeConfidentialite />
                    </PrivateRoute>
                  }
                />
              </Routes>
            </Container>
            <Footer />
          </div>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;

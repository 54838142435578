import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  CircularProgress,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
} from "firebase/firestore";
import { useAuth } from "../authContext";
import {
  fetchOpenAiData,
  generateAndStoreInterviewQuestions,
} from "../api/fetchOpenAiData";
import { useNavigate } from "react-router-dom";

const InterviewQuestions = () => {
  const { currentUser } = useAuth();
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const db = getFirestore();

  useEffect(() => {
    const checkUserProfile = async () => {
      if (!currentUser) return false;

      const userDoc = doc(db, "Users", currentUser.email);
      const userSnapshot = await getDoc(userDoc);
      if (userSnapshot.exists()) {
        const userData = userSnapshot.data();
        if (
          !userData.skills ||
          !userData.interestSectors ||
          !userData.careerGoals
        ) {
          setOpenDialog(true);
          return false;
        }
      } else {
        setOpenDialog(true);
        return false;
      }
      return true;
    };

    const fetchAndStoreQuestions = async () => {
      if (!currentUser) {
        setLoading(false);
        return;
      }

      try {
        const interviewQuestionsCollection = collection(
          db,
          "interviewQuestions"
        );
        const q = query(
          interviewQuestionsCollection,
          where("userId", "==", currentUser.email)
        );
        const querySnapshot = await getDocs(q);
        const questionsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        if (questionsData.length <= 20) {
          const userData = await fetchOpenAiData(currentUser);
          await generateAndStoreInterviewQuestions(userData, currentUser);

          const newQuerySnapshot = await getDocs(q);
          const newQuestionsData = newQuerySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setQuestions(newQuestionsData);
        } else {
          setQuestions(questionsData);
        }
      } catch (err) {
        if (err.message.includes("parsing JSON")) {
          setError(
            "La création des questions a été interrompue. Merci de tenter à nouveau."
          );
          navigate("/preparation");
        } else {
          setError(err.message);
        }
      } finally {
        setLoading(false);
      }
    };

    const initializeComponent = async () => {
      const isUserProfileComplete = await checkUserProfile();
      if (isUserProfileComplete) {
        await fetchAndStoreQuestions();
      } else {
        setLoading(false);
      }
    };

    initializeComponent();
  }, [currentUser, db, navigate]);

  const handleCloseDialog = () => {
    navigate("/profile");
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress size={60} />
        <Typography variant="h6" sx={{ ml: 2 }}>
          Chargement des questions...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <ErrorOutlineIcon color="error" sx={{ mr: 1 }} />
        <Typography color="error" variant="h6">
          {error}
        </Typography>
      </Box>
    );
  }

  const cardColors = ["#FFCDD2", "#C8E6C9", "#BBDEFB"];

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
        <QuestionAnswerIcon sx={{ mr: 1 }} />
        <Typography variant="h4" gutterBottom>
          Questions d'Entretien Courantes
        </Typography>
      </Box>
      {questions.length > 0 ? (
        <Box>
          {questions.map((question, index) => (
            <Card
              key={question.id}
              sx={{
                mb: 2,
                backgroundColor: cardColors[index % cardColors.length],
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": { transform: "scale(1.05)", boxShadow: 6 },
              }}
              elevation={3}
            >
              <CardContent>
                <Typography variant="h6">
                  {question.l_question[0].question}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Réponse : {question.l_question[0].answer}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      ) : (
        <Typography variant="body1">Aucune question trouvée.</Typography>
      )}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Profil Incomplet"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Veuillez renseigner vos informations de carrière dans le menu
            profil.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default InterviewQuestions;

import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Avatar,
  Button,
} from "@mui/material";
import { Rating } from "@mui/lab";
import { green, red, blue, yellow } from "@mui/material/colors";
import DoneIcon from "@mui/icons-material/Done";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import InfoIcon from "@mui/icons-material/Info";
import StarIcon from "@mui/icons-material/Star";
import { getAuth } from "firebase/auth";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";

function createData(question, response, score, feedback) {
  return { question, response, score, feedback };
}

function getFeedbackIcon(score) {
  if (score >= 8) {
    return <DoneIcon sx={{ color: green[500] }} />;
  } else if (score >= 6) {
    return <InfoIcon sx={{ color: blue[500] }} />;
  } else {
    return <PriorityHighIcon sx={{ color: red[500] }} />;
  }
}

function Analysis() {
  const [rows, setRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  useEffect(() => {
    const fetchData = async () => {
      const auth = getAuth();
      const currentUser = auth.currentUser;

      if (!currentUser) {
        console.error("Utilisateur non connecté");
        return;
      }

      const simulationsCollection = collection(db, "simulations");
      const q = query(
        simulationsCollection,
        where("userid", "==", currentUser.email),
        where("status", "==", "Terminé")
      );

      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        console.error("Aucune simulation en cours trouvée");
        return;
      }

      const simulations = querySnapshot.docs
        .map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

      const allRows = simulations.flatMap((simulation) =>
        simulation.detail.map((q) =>
          createData(q.question, q.response, q.rating, q.feedback)
        )
      );

      setRows(allRows);
    };

    fetchData();
  }, []);

  const handlePageChange = (direction) => {
    setCurrentPage((prevPage) => prevPage + direction);
  };

  const displayedRows = rows.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(rows.length / itemsPerPage);

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Analyse Détailée des Réponses
      </Typography>
      <Typography variant="body1" gutterBottom>
        Ici, vous pouvez voir l'analyse détaillée de vos réponses avec des
        scores et des feedbacks.
      </Typography>
      <Grid container spacing={3}>
        {displayedRows.map((row, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": { transform: "scale(1.05)", boxShadow: 6 },
              }}
              elevation={3}
            >
              <CardContent>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <Avatar sx={{ mr: 2, bgcolor: yellow[700] }}>
                    <StarIcon />
                  </Avatar>
                  <Typography variant="h6" component="div">
                    {row.question}
                  </Typography>
                </Box>
                <Typography variant="body1" color="textSecondary" paragraph>
                  <strong>Réponse :</strong> {row.response}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <Rating value={row.score / 2} readOnly />
                  <Typography variant="body1" sx={{ ml: 2 }}>
                    {row.score} / 10
                  </Typography>
                </Box>
                <Typography variant="body2" color="textSecondary">
                  <strong>Feedback :</strong> {row.feedback}
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                  {getFeedbackIcon(row.score)}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
        <Button
          variant="contained"
          color="primary"
          disabled={currentPage === 1 || rows.length === 0}
          onClick={() => handlePageChange(-1)}
          sx={{ mr: 2 }}
        >
          Précédent
        </Button>
        <Typography variant="body1" sx={{ alignSelf: "center" }}>
          Page {currentPage} sur {totalPages}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          disabled={currentPage === totalPages || rows.length === 0}
          onClick={() => handlePageChange(1)}
          sx={{ ml: 2 }}
        >
          Suivant
        </Button>
      </Box>
    </Box>
  );
}

export default Analysis;

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Avatar,
  CircularProgress,
} from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { NavLink } from "react-router-dom";
import PeopleIcon from "@mui/icons-material/People";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import SettingsIcon from "@mui/icons-material/Settings";
import BarChartIcon from "@mui/icons-material/BarChart";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { db } from "../firebaseConfig";
import { collection, getDocs } from "firebase/firestore";

const data = [
  { name: "Jan", users: 4000, sales: 2400 },
  { name: "Feb", users: 3000, sales: 1398 },
  { name: "Mar", users: 2000, sales: 9800 },
  { name: "Apr", users: 2780, sales: 3908 },
  { name: "May", users: 1890, sales: 4800 },
  { name: "Jun", users: 2390, sales: 3800 },
  { name: "Jul", users: 3490, sales: 4300 },
];

const fetchSubscriptionCounts = async () => {
  try {
    const usersCollection = collection(db, "Users");
    const usersSnapshot = await getDocs(usersCollection);

    let activeCount = 0;

    for (const userDoc of usersSnapshot.docs) {
      const subscriptionsCollection = collection(userDoc.ref, "subscriptions");
      const subscriptionsSnapshot = await getDocs(subscriptionsCollection);

      subscriptionsSnapshot.forEach((subscriptionDoc) => {
        const subscriptionData = subscriptionDoc.data();
        if (subscriptionData.status === "active") {
          activeCount++;
        }
      });
    }

    return activeCount;
  } catch (err) {
    console.error("Error fetching subscription counts:", err);
    throw err;
  }
};

const fetchRevenue = async () => {
  try {
    const usersCollection = collection(db, "Users");
    const usersSnapshot = await getDocs(usersCollection);

    let totalRevenue = 0;

    for (const userDoc of usersSnapshot.docs) {
      const subscriptionsCollection = collection(userDoc.ref, "subscriptions");
      const subscriptionsSnapshot = await getDocs(subscriptionsCollection);

      subscriptionsSnapshot.forEach((subscriptionDoc) => {
        const subscriptionData = subscriptionDoc.data();
        if (subscriptionData.status === "active") {
          subscriptionData.items.forEach((item) => {
            const amount = item.plan.amount;
            totalRevenue += amount;
          });
        }
      });
    }

    // Convertir les centimes en euros si nécessaire
    return (totalRevenue / 100).toFixed(2);
  } catch (err) {
    console.error("Error fetching revenue:", err);
    throw err;
  }
};

const DashboardAdmin = () => {
  const [activeUsersCount, setActiveUsersCount] = useState(0);
  const [newSubscriptionsCount, setNewSubscriptionsCount] = useState(0);
  const [revenue, setRevenue] = useState("0.00");

  // Créer des états de chargement spécifiques à chaque carte
  const [loadingActiveUsers, setLoadingActiveUsers] = useState(true);
  const [loadingSubscriptions, setLoadingSubscriptions] = useState(true);
  const [loadingRevenue, setLoadingRevenue] = useState(true);

  const fetchCounts = async () => {
    try {
      // Récupération des utilisateurs actifs
      const usersCollection = collection(db, "Users");
      const userSnapshot = await getDocs(usersCollection);
      const userList = userSnapshot.docs.map((doc) => doc.data());
      const activeUsers = userList.filter((user) => user.status === "Active").length;
      setActiveUsersCount(activeUsers);
      setLoadingActiveUsers(false); // Désactiver le chargement pour cette carte

      // Récupération des abonnements actifs
      const activeSubscriptionsCount = await fetchSubscriptionCounts();
      setNewSubscriptionsCount(activeSubscriptionsCount);
      setLoadingSubscriptions(false); // Désactiver le chargement pour cette carte

      // Récupération des revenus
      const revenueAmount = await fetchRevenue();
      setRevenue(revenueAmount);
      setLoadingRevenue(false); // Désactiver le chargement pour cette carte
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
      setLoadingActiveUsers(false);
      setLoadingSubscriptions(false);
      setLoadingRevenue(false);
    }
  };

  useEffect(() => {
    fetchCounts();

    const intervalId = setInterval(fetchCounts, 60000); // Actualisation toutes les 60 secondes

    return () => clearInterval(intervalId); // Nettoyage de l'intervalle lors du démontage du composant
  }, []);

  return (
    <Box sx={{ p: 3, backgroundColor: "#f5f5f5", minHeight: "100vh" }}>
      <Typography variant="h4" gutterBottom>
        Tableau de Bord
      </Typography>
      <Grid container spacing={3}>
        {/* Carte Utilisateurs actifs */}
        <Grid item xs={12} md={4}>
          <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
            <CardContent>
              <Avatar sx={{ bgcolor: "#1976d2", mb: 2 }}>
                <PeopleIcon />
              </Avatar>
              <Typography variant="h6">Utilisateurs actifs</Typography>
              {loadingActiveUsers ? (
                <CircularProgress />
              ) : (
                <Typography variant="h3" sx={{ fontWeight: "bold" }}>
                  {activeUsersCount}
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Carte Nouveaux abonnements */}
        <Grid item xs={12} md={4}>
          <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
            <CardContent>
              <Avatar sx={{ bgcolor: "#ff9800", mb: 2 }}>
                <PersonAddIcon />
              </Avatar>
              <Typography variant="h6">Abonnements actifs</Typography>
              {loadingSubscriptions ? (
                <CircularProgress />
              ) : (
                <Typography variant="h3" sx={{ fontWeight: "bold" }}>
                  {newSubscriptionsCount}
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Carte Revenu */}
        <Grid item xs={12} md={4}>
          <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
            <CardContent>
              <Avatar sx={{ bgcolor: "#4caf50", mb: 2 }}>
                <MonetizationOnIcon />
              </Avatar>
              <Typography variant="h6">Revenu</Typography>
              {loadingRevenue ? (
                <CircularProgress />
              ) : (
                <Typography variant="h3" sx={{ fontWeight: "bold" }}>
                  €{revenue}
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Statistiques des ventes */}
        <Grid item xs={12}>
          <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6">Statistiques des ventes</Typography>
              <LineChart width={600} height={300} data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="sales" stroke="#8884d8" />
                <Line type="monotone" dataKey="users" stroke="#82ca9d" />
              </LineChart>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Box
        sx={{
          mt: 4,
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <NavLink to="/UserManagement" style={{ textDecoration: "none" }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<PeopleIcon />}
            sx={{ minWidth: 150 }}
          >
            Utilisateurs
          </Button>
        </NavLink>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SubscriptionsIcon />}
          sx={{ minWidth: 150 }}
        >
          Abonnements
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SettingsIcon />}
          sx={{ minWidth: 150 }}
        >
          Paramètres
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<BarChartIcon />}
          sx={{ minWidth: 150 }}
        >
          Rapports
        </Button>
      </Box>
    </Box>
  );
};

export default DashboardAdmin;


import React, { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import { doc, updateDoc, getDoc, collection, query, where, getDocs } from "firebase/firestore";
import AddIcon from "@mui/icons-material/Add";
import {
  Paper,
  TextField,
  Typography,
  Button,
  List,
  ListItem,
  IconButton,
  Tooltip,
  Snackbar,
  Alert,
  Pagination,
  Divider,
  Tabs,
  Tab,
  Box,
  CircularProgress,
  Card,
  CardContent,
  Grid,
  Switch,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import styled from "@emotion/styled";
import { analyzeCandidateExperiences } from "../api/fetchOpenAiData";

const StyledPaper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(3)};
  margin-top: ${(props) => props.theme.spacing(3)};
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
  box-shadow: ${(props) => props.theme.shadows[3]};
`;

const StyledListItem = styled(ListItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${(props) => props.theme.palette.divider};
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
  padding: ${(props) => props.theme.spacing(1)};
  margin-bottom: ${(props) => props.theme.spacing(1)};
  transition: background-color 0.3s;
  &:hover {
    background-color: ${(props) => props.theme.palette.action.hover};
  }
`;

const ContentBox = styled(Box)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const ActionsBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing(1)};
`;

const CVCard = styled(Card)`
  margin-top: ${(props) => props.theme.spacing(2)};
  margin-bottom: ${(props) => props.theme.spacing(2)};
  background-color: #f9f9f9;
`;

const CVTypography = styled(Typography)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.primary.main};
`;

const CVDetail = styled(Typography)`
  color: ${(props) => props.theme.palette.text.secondary};
`;

const Candidature = ({ offreId, onClose }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [offerName, setOfferName] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [tabIndex, setTabIndex] = useState(0);
  const [automaticCandidates, setAutomaticCandidates] = useState([]);
  const [loadingAutomatic, setLoadingAutomatic] = useState(false);
  const [showCV, setShowCV] = useState({}); // State to track which CVs are visible
  const pageSize = 5;

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
    if (newIndex === 1) {
      fetchAutomaticCandidates();
    }
  };

  const fetchUsers = async (page = 1) => {
    try {
      const userCollection = collection(db, "Users");
      const q = query(
        userCollection,
        where("name", ">=", searchTerm),
        where("name", "<=", searchTerm + "\uf8ff")
      );

      const usersSnapshot = await getDocs(q);
      const usersList = usersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const filteredUsers = usersList.filter(
        (user) => !selectedCandidates.some((candidate) => candidate.userID === user.id)
      );

      const paginatedUsers = filteredUsers.slice((page - 1) * pageSize, page * pageSize);
      setUsers(paginatedUsers);
      setTotalPages(Math.ceil(filteredUsers.length / pageSize));
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchAutomaticCandidates = async () => {
    if (!offreId) return;

    setLoadingAutomatic(true);

    try {
      const candidates = await analyzeCandidateExperiences(offreId);

      const filteredCandidates = candidates.filter(
        (candidate) => !selectedCandidates.some((selected) => selected.userID === candidate.userID)
      );

      setAutomaticCandidates(filteredCandidates);
    } catch (error) {
      console.error("Error fetching automatic candidates:", error);
    } finally {
      setLoadingAutomatic(false);
    }
  };

  useEffect(() => {
    if (searchTerm) {
      fetchUsers();
    }
  }, [searchTerm]);

  useEffect(() => {
    loadSelectedCandidates();
  }, [offreId]);

  useEffect(() => {
    fetchUsers(page);
  }, [page]);

  const loadSelectedCandidates = async () => {
    if (offreId) {
      try {
        const offreDoc = doc(db, "offres_emploi", offreId);
        const offreSnapshot = await getDoc(offreDoc);
        const offreData = offreSnapshot.data();
  
        // Vérification et initialisation de `l_candidats`
        const selectedCandidates = Array.isArray(offreData?.l_candidats) ? offreData.l_candidats : [];
  
        const updatedCandidates = [];
  
        for (let candidate of selectedCandidates) {
          const candidateDoc = doc(db, "Users", candidate.email);
          const candidateSnapshot = await getDoc(candidateDoc);
          if (candidateSnapshot.exists()) {
            const candidateData = candidateSnapshot.data();
            updatedCandidates.push({
              ...candidate,
              careerGoals: candidateData.careerGoals || "N/A",
              interestSectors: candidateData.interestSectors || [],
              skills: candidateData.skills || [],
              experiences: candidateData.experiences || [],
            });
          } else {
            updatedCandidates.push(candidate);
          }
        }
  
        setSelectedCandidates(updatedCandidates);
        setOfferName(offreData?.titre || "");
      } catch (error) {
        console.error("Error loading selected candidates:", error);
        setSelectedCandidates([]);
      }
    }
  };
  

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(1);
  };

  const handleAddCandidate = async (user) => {
    if (!user || !user.id || !offreId) {
      console.error("Incomplete user or offreId");
      return;
    }
  
    try {
      // Récupérer les données complètes de l'utilisateur
      const userDoc = doc(db, "Users", user.id);
      const userSnapshot = await getDoc(userDoc);
  
      if (!userSnapshot.exists()) {
        console.error("User not found");
        return;
      }
  
      const userData = userSnapshot.data();
  
      const candidats = [
        ...selectedCandidates,
        {
          userID: user.id,
          name: userData.name,
          email: userData.email || "",
          phone: userData.phone || "",
          interviewnote: 0,
          interviewcomment: "",
          interview_done: false,
          interview_status: "Planned",
          confirmed: false, // Initialisation de confirmed
          careerGoals: userData.careerGoals || "N/A",
          interestSectors: userData.interestSectors || [],
          skills: userData.skills || [],
          experiences: userData.experiences || [],
        },
      ];
  
      await updateDoc(doc(db, "offres_emploi", offreId), { l_candidats: candidats });
      setSelectedCandidates(candidats);
      setOpenSnackbar(true);
  
      setAutomaticCandidates(automaticCandidates.filter((c) => c.userID !== user.id));
      setUsers(users.filter((u) => u.id !== user.id));
    } catch (error) {
      console.error("Error adding candidate:", error);
    }
  };
  

  const handleRemoveCandidate = async (userID) => {
    try {
      const offreDoc = doc(db, "offres_emploi", offreId);
      const updatedCandidates = selectedCandidates.filter(
        (candidate) => candidate.userID !== userID
      );

      await updateDoc(offreDoc, { l_candidats: updatedCandidates });
      setSelectedCandidates(updatedCandidates);
    } catch (error) {
      console.error("Error removing candidate:", error);
    }
  };

  const handleToggleConfirmed = async (userID) => {
    try {
      const updatedCandidates = selectedCandidates.map((candidate) =>
        candidate.userID === userID
          ? { ...candidate, confirmed: !candidate.confirmed }
          : candidate
      );

      await updateDoc(doc(db, "offres_emploi", offreId), { l_candidats: updatedCandidates });
      setSelectedCandidates(updatedCandidates);
    } catch (error) {
      console.error("Error updating confirmed status:", error);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const toggleCVVisibility = (userID) => {
    setShowCV((prevState) => ({
      ...prevState,
      [userID]: !prevState[userID],
    }));
  };

  return (
    <StyledPaper>
      <Typography variant="h5" gutterBottom>
        Sélectionner des candidats pour l'offre: {offerName}
      </Typography>

      <Tabs value={tabIndex} onChange={handleTabChange}>
        <Tab label="Manuelle" />
        <Tab label="Automatique" />
      </Tabs>

      <Box sx={{ paddingTop: 2 }}>
        {tabIndex === 0 && (
          <>
            <TextField
              label="Rechercher par nom ou email"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchChange}
              fullWidth
              sx={{ marginBottom: 2 }}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<SearchIcon />}
              onClick={() => fetchUsers(page)}
              sx={{ marginBottom: 2 }}
            >
              Rechercher
            </Button>

            <List>
              {users.map((user) => (
                <StyledListItem key={user.id}>
                  <ContentBox>
                    <Typography variant="body1">
                      {user.name} ({user.email})
                    </Typography>
                  </ContentBox>
                  <ActionsBox>
                    <Tooltip title="Ajouter">
                      <IconButton onClick={() => handleAddCandidate(user)}>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  </ActionsBox>
                </StyledListItem>
              ))}
            </List>

            <Pagination
              count={totalPages}
              page={page}
              onChange={(event, value) => setPage(value)}
              color="primary"
              sx={{ marginTop: 2, marginBottom: 2 }}
            />

            <Divider sx={{ marginY: 2 }} />

            <Typography variant="h5" gutterBottom>
              Candidats Sélectionnés
            </Typography>

            <List>
              {selectedCandidates.map((candidate) => (
                <StyledListItem key={candidate.userID}>
                  <ContentBox>
                    <Typography variant="body1">
                      {candidate.name} ({candidate.email})
                    </Typography>
                    {showCV[candidate.userID] && (
                      <CVCard>
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <CVTypography>Objectifs de carrière:</CVTypography>
                              <CVDetail>{candidate.careerGoals || "N/A"}</CVDetail>
                            </Grid>
                            <Grid item xs={12}>
                              <CVTypography>Secteurs d'intérêt:</CVTypography>
                              <CVDetail>
                                {candidate.interestSectors.length > 0
                                  ? candidate.interestSectors.join(", ")
                                  : "N/A"}
                              </CVDetail>
                            </Grid>
                            <Grid item xs={12}>
                              <CVTypography>Compétences:</CVTypography>
                              <CVDetail>
                                {candidate.skills.length > 0
                                  ? candidate.skills.join(", ")
                                  : "N/A"}
                              </CVDetail>
                            </Grid>
                            <Grid item xs={12}>
                              <CVTypography>Expériences professionnelles:</CVTypography>
                              {candidate.experiences.length > 0 ? (
                                <List>
                                  {candidate.experiences.map((exp, index) => (
                                    <ListItem key={index}>
                                      <CVDetail>
                                        {exp.position}, {exp.company}, {exp.duration}
                                      </CVDetail>
                                    </ListItem>
                                  ))}
                                </List>
                              ) : (
                                <CVDetail>N/A</CVDetail>
                              )}
                            </Grid>
                          </Grid>
                        </CardContent>
                      </CVCard>
                    )}
                    <Button
                      size="small"
                      onClick={() => toggleCVVisibility(candidate.userID)}
                      sx={{ marginTop: 1 }}
                    >
                      {showCV[candidate.userID] ? "MASQUER CV" : "VOIR CV"}
                    </Button>
                  </ContentBox>
                  <ActionsBox>
                    {!candidate.interview_done && !(candidate.interviewnote || candidate.interviewcomment) && (
                      <>
                        <Switch
                          checked={candidate.confirmed || false}
                          onChange={() => handleToggleConfirmed(candidate.userID)}
                          color="primary"
                          inputProps={{ "aria-label": "toggle confirmed" }}
                        />
                      </>
                    )}
                    {!candidate.interviewnote && !candidate.interviewcomment && (
                      <Tooltip title="Supprimer">
                        <IconButton onClick={() => handleRemoveCandidate(candidate.userID)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </ActionsBox>
                </StyledListItem>
              ))}
            </List>


          </>
        )}
        {tabIndex === 1 && (
          <>
            {loadingAutomatic ? (
              <CircularProgress />
            ) : (
              <>
                <Typography variant="h5" gutterBottom>
                  Candidats Sélectionnés
                </Typography>
                <List>
                  {selectedCandidates.map((candidate) => (
                    <StyledListItem key={candidate.userID}>
                      <ContentBox>
                        <Typography variant="body1">
                          {candidate.name} ({candidate.email})
                        </Typography>
                        {showCV[candidate.userID] && (
                          <CVCard>
                            <CardContent>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <CVTypography>Objectifs de carrière:</CVTypography>
                                  <CVDetail>{candidate.careerGoals || "N/A"}</CVDetail>
                                </Grid>
                                <Grid item xs={12}>
                                  <CVTypography>Secteurs d'intérêt:</CVTypography>
                                  <CVDetail>
                                    {candidate.interestSectors && candidate.interestSectors.length > 0
                                      ? candidate.interestSectors.join(", ")
                                      : "N/A"}
                                  </CVDetail>
                                </Grid>
                                <Grid item xs={12}>
                                  <CVTypography>Compétences:</CVTypography>
                                  <CVDetail>
                                    {candidate.skills && candidate.skills.length > 0
                                      ? candidate.skills.join(", ")
                                      : "N/A"}
                                  </CVDetail>
                                </Grid>
                                <Grid item xs={12}>
                                  <CVTypography>Expériences professionnelles:</CVTypography>
                                  {candidate.experiences && candidate.experiences.length > 0 ? (
                                    <List>
                                      {candidate.experiences.map((exp, index) => (
                                        <ListItem key={index}>
                                          <CVDetail>
                                            {exp.position}, {exp.company}, {exp.duration}
                                          </CVDetail>
                                        </ListItem>
                                      ))}
                                    </List>
                                  ) : (
                                    <CVDetail>N/A</CVDetail>
                                  )}
                                </Grid>
                              </Grid>
                            </CardContent>
                          </CVCard>
                        )}
                        <Button
                          size="small"
                          onClick={() => toggleCVVisibility(candidate.userID)}
                          sx={{ marginTop: 1 }}
                        >
                          {showCV[candidate.userID] ? "MASQUER CV" : "VOIR CV"}
                        </Button>
                      </ContentBox>
                      <ActionsBox>
                        {!candidate.interview_done && (
                          <>
                            <Switch
                              checked={candidate.confirmed || false}
                              onChange={() => handleToggleConfirmed(candidate.userID)}
                              color="primary"
                              inputProps={{ "aria-label": "toggle confirmed" }}
                            />
                            {!(candidate.interviewnote || candidate.interviewcomment) && (
                              <Tooltip title="Supprimer">
                                <IconButton onClick={() => handleRemoveCandidate(candidate.userID)}>
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          </>
                        )}
                      </ActionsBox>
                    </StyledListItem>
                  ))}
                </List>

                <Divider sx={{ marginY: 2 }} />

                <Typography variant="h5" gutterBottom>
                  Candidats Automatiques
                </Typography>
                <List>
                  {automaticCandidates.map((candidate) => (
                    <StyledListItem key={candidate.userID}>
                      <ContentBox>
                        <Typography variant="body1">
                          {candidate.userID} - Score: {candidate.pertinence}%
                        </Typography>
                        <Typography variant="body2">
                          {candidate.commentaire}
                        </Typography>
                      </ContentBox>
                      <ActionsBox>
                        <Tooltip title="Ajouter">
                          <IconButton onClick={() => handleAddCandidate({
                            id: candidate.userID,
                            name: candidate.userID,
                            email: "",
                            phone: "",
                          })}>
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                      </ActionsBox>
                    </StyledListItem>
                  ))}
                </List>
              </>
            )}
          </>
        )}
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          Candidat mis à jour avec succès !
        </Alert>
      </Snackbar>

      <Button
        variant="outlined"
        color="secondary"
        onClick={onClose}
        sx={{ marginTop: 2 }}
      >
        Fermer
      </Button>
    </StyledPaper>
  );
};

export default Candidature;

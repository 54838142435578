import React from "react";
import { Box, Typography, Paper } from "@mui/material";

function ConditionsPdfViewer() {
  return (
    <Box sx={{ p: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom align="center">
          Conditions d'utilisation de l'application "JobJourney"
        </Typography>

        <Typography variant="h5" gutterBottom>
          Introduction
        </Typography>
        <Typography paragraph>
          Les présentes conditions d'utilisation ("Conditions") régissent votre
          utilisation de l'application "JobJourney" (ci-après dénommée
          "l'Application"), une plateforme interactive et personnalisée pour la
          simulation d'entretiens d'embauche. En utilisant l'Application, vous
          acceptez de respecter et d'être lié par ces Conditions. Si vous
          n'acceptez pas ces Conditions, vous ne devez pas utiliser
          l'Application.
        </Typography>

        <Typography variant="h6" gutterBottom>
          1. Inscription et Authentification
        </Typography>
        <Typography paragraph>
          1.1. Pour utiliser l'Application, vous devez vous inscrire en
          fournissant une adresse email valide et créer un mot de passe. Vous
          pouvez également vous inscrire via un compte Google.
        </Typography>
        <Typography paragraph>
          1.2. Vous êtes responsable de la confidentialité de vos informations
          de connexion et de toutes les activités effectuées sous votre compte.
          Si vous suspectez une utilisation non autorisée de votre compte, vous
          devez nous en informer immédiatement.
        </Typography>

        <Typography variant="h6" gutterBottom>
          2. Utilisation de l'Application
        </Typography>
        <Typography paragraph>
          2.1. L'Application est destinée à des fins personnelles et non
          commerciales. Vous ne devez pas utiliser l'Application d'une manière
          qui pourrait endommager, désactiver, surcharger ou compromettre les
          serveurs ou les réseaux connectés à l'Application.
        </Typography>
        <Typography paragraph>
          2.2. Vous acceptez de ne pas reproduire, dupliquer, copier, vendre,
          revendre ou exploiter à des fins commerciales toute partie de
          l'Application sans notre consentement écrit préalable.
        </Typography>

        <Typography variant="h6" gutterBottom>
          3. Contenu Utilisateur
        </Typography>
        <Typography paragraph>
          3.1. Vous êtes seul responsable du contenu que vous téléchargez,
          publiez ou affichez via l'Application ("Contenu Utilisateur"). Cela
          inclut, mais n'est pas limité à, les informations personnelles, les
          vidéos et les enregistrements audio de vos simulations d'entretien.
        </Typography>
        <Typography paragraph>
          3.2. Vous accordez à JobJourney une licence non exclusive, mondiale,
          libre de redevances et transférable pour utiliser, reproduire,
          distribuer, préparer des œuvres dérivées, afficher et exécuter votre
          Contenu Utilisateur dans le cadre de la fourniture de l'Application.
        </Typography>

        <Typography variant="h6" gutterBottom>
          4. Protection des Données
        </Typography>
        <Typography paragraph>
          4.1. Nous nous engageons à protéger vos données personnelles et à
          respecter votre vie privée. Vos données seront stockées de manière
          sécurisée conformément aux normes GDPR dans Google Cloud Firestore.
        </Typography>
        <Typography paragraph>
          4.2. Nous utiliserons vos données pour fournir et améliorer
          l'Application, notamment en utilisant des technologies de
          reconnaissance vocale et vidéo pour analyser vos simulations et
          fournir des feedbacks.
        </Typography>

        <Typography variant="h6" gutterBottom>
          5. Sécurité
        </Typography>
        <Typography paragraph>
          5.1. Nous mettons en œuvre des mesures de sécurité raisonnables pour
          protéger vos informations contre tout accès non autorisé, utilisation
          ou divulgation. Cependant, aucune méthode de transmission sur
          Internet ou de stockage électronique n'est totalement sécurisée.
        </Typography>
        <Typography paragraph>
          5.2. Vous acceptez de ne pas tenter de contourner, désactiver ou
          interférer de toute autre manière avec les fonctionnalités de sécurité
          de l'Application.
        </Typography>

        <Typography variant="h6" gutterBottom>
          6. Limitation de Responsabilité
        </Typography>
        <Typography paragraph>
          6.1. L'Application est fournie "telle quelle" et "selon
          disponibilité". JobJourney ne garantit pas que l'Application sera
          ininterrompue, exempte d'erreurs ou sécurisée.
        </Typography>
        <Typography paragraph>
          6.2. Dans la mesure maximale permise par la loi applicable, JobJourney
          ne sera pas responsable de tout dommage indirect, accessoire, spécial,
          consécutif ou punitif, ou de toute perte de profits ou de revenus,
          encourus directement ou indirectement, ou de toute perte de données,
          d'utilisation, de bonne volonté, ou d'autres pertes intangibles
          résultant de (i) votre utilisation ou incapacité à utiliser
          l'Application; (ii) tout accès non autorisé ou utilisation de nos
          serveurs et/ou de toute information personnelle stockée sur ceux-ci.
        </Typography>

        <Typography variant="h6" gutterBottom>
          7. Modifications des Conditions
        </Typography>
        <Typography paragraph>
          7.1. JobJourney se réserve le droit de modifier ces Conditions à tout
          moment. Nous vous informerons des modifications en publiant les
          nouvelles Conditions sur l'Application. Votre utilisation continue de
          l'Application après de telles modifications constitue votre acceptation
          des nouvelles Conditions.
        </Typography>

        <Typography variant="h6" gutterBottom>
          8. Résiliation
        </Typography>
        <Typography paragraph>
          8.1. JobJourney peut suspendre ou résilier votre accès à
          l'Application, sans préavis ni responsabilité, pour quelque raison que
          ce soit, y compris si vous violez les présentes Conditions.
        </Typography>
        <Typography paragraph>
          8.2. En cas de résiliation, votre droit d'utiliser l'Application
          cessera immédiatement et toutes les dispositions des Conditions qui,
          de par leur nature, devraient survivre à la résiliation, survivront,
          y compris, sans limitation, les dispositions relatives à la propriété,
          les exclusions de garantie et les limitations de responsabilité.
        </Typography>

        <Typography variant="h6" gutterBottom>
          9. Droit Applicable
        </Typography>
        <Typography paragraph>
          9.1. Les présentes Conditions sont régies et interprétées
          conformément aux lois en vigueur dans le pays où JobJourney a son
          siège social, sans tenir compte de ses dispositions en matière de
          conflit de lois.
        </Typography>

        <Typography variant="h6" gutterBottom>
          10. Contact
        </Typography>
        <Typography paragraph>
          10.1. Si vous avez des questions concernant ces Conditions, veuillez
          nous contacter à l'adresse suivante : contact@jobjourneyapp.com.
        </Typography>

        <Typography variant="body1" paragraph align="center" sx={{ mt: 4 }}>
          En utilisant l'Application, vous reconnaissez avoir lu, compris et
          accepté d'être lié par les présentes Conditions d'utilisation.
        </Typography>
      </Paper>
    </Box>
  );
}

export default ConditionsPdfViewer;

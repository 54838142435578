import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Paper,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import AssessmentIcon from "@mui/icons-material/Assessment";
import RestoreIcon from "@mui/icons-material/Restore";
import { db } from "../firebaseConfig";
import { collection, query, where, getDocs, doc, getDoc } from "firebase/firestore";
import { useAuth } from "../authContext";
import { fetchUserSubscriptions } from "../fetchUserSubscriptions"; // Assurez-vous que le chemin est correct

function Preparation() {
  const [pastEvaluations, setPastEvaluations] = useState([]);
  const [isFreePlan, setIsFreePlan] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const subscriptions = await fetchUserSubscriptions(() => {});
        if (subscriptions.length > 0) {
          const user = subscriptions[0];
          setIsFreePlan(user.name === "Free");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des informations d'abonnement :", error);
      }
    };

    const fetchPastEvaluations = async () => {
      if (currentUser) {
        const q = query(
          collection(db, "quiztest"),
          where("userid", "==", currentUser.email),
          where("status", "==", "Terminé")
        );
        const querySnapshot = await getDocs(q);
        const evaluations = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          evaluations.push({
            timestamp: data.timestamp,
            score: data.score,
          });
        });
        evaluations.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        setPastEvaluations(evaluations);
      }
    };

    fetchUserData();
    fetchPastEvaluations();
  }, [currentUser]);

  const checkCareerGoals = async () => {
    if (currentUser) {
      const userDoc = doc(db, "Users", currentUser.email);
      const userSnapshot = await getDoc(userDoc);
      if (userSnapshot.exists()) {
        const userData = userSnapshot.data();
        if (!userData.careerGoals) {
          setOpenDialog(true);
          return false;
        }
      } else {
        setOpenDialog(true);
        return false;
      }
    }
    return true;
  };

  const handleSimulationsClick = async (e) => {
    const isCareerGoalsSet = await checkCareerGoals();
    if (!isCareerGoalsSet) {
      e.preventDefault();
    }
  };

  const handleEvaluationClick = async (e) => {
    const isCareerGoalsSet = await checkCareerGoals();
    if (!isCareerGoalsSet) {
      e.preventDefault();
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    navigate("/profile");
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Préparation
      </Typography>
      <Typography variant="body1" gutterBottom>
        Accédez à des ressources et des outils pour vous aider à vous préparer
        pour vos entretiens et réussir dans vos candidatures.
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper
            sx={{
              p: 2,
              transition: "transform 0.3s, box-shadow 0.3s",
              "&:hover": { transform: "scale(1.05)", boxShadow: 6 },
            }}
            elevation={3}
          >
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <MenuBookIcon sx={{ mr: 1 }} />
              <Typography variant="h6" gutterBottom>
                Guides et Ressources
              </Typography>
            </Box>
            <Typography variant="body2">
              Guides, astuces et conseils utiles pour vous aider à préparer vos
              entretiens.
            </Typography>
            <Button variant="contained" color="primary" sx={{ mt: 2 }}>
              <Link
                to="/guidesressource"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                Accéder
              </Link>
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            sx={{
              p: 2,
              transition: "transform 0.3s, box-shadow 0.3s",
              "&:hover": { transform: "scale(1.05)", boxShadow: 6 },
            }}
            elevation={3}
          >
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <QuestionAnswerIcon sx={{ mr: 1 }} />
              <Typography variant="h6" gutterBottom>
                Questions d'Entretien Courantes
              </Typography>
            </Box>
            <Typography variant="body2">
              Liste des questions courantes avec des exemples de réponses.
            </Typography>
            <Button variant="contained" color="primary" sx={{ mt: 2 }}>
              <Link
                to="/interviewquestions"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                Voir les questions
              </Link>
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            sx={{
              p: 2,
              transition: "transform 0.3s, box-shadow 0.3s",
              "&:hover": { transform: "scale(1.05)", boxShadow: 6 },
            }}
            elevation={3}
          >
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <PlayCircleFilledIcon sx={{ mr: 1 }} />
              <Typography variant="h6" gutterBottom>
                Simulations d'Entretien
              </Typography>
            </Box>
            <Typography variant="body2">
              Pratiquez vos entretiens avec des simulations et obtenez du
              feedback.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              disabled={isFreePlan}
            >
              <Link
                to="/simulations"
                style={{ color: "inherit", textDecoration: "none" }}
                onClick={handleSimulationsClick}
              >
                Commencer
              </Link>
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            sx={{
              p: 2,
              transition: "transform 0.3s, box-shadow 0.3s",
              "&:hover": { transform: "scale(1.05)", boxShadow: 6 },
            }}
            elevation={3}
          >
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <AssessmentIcon sx={{ mr: 1 }} />
              <Typography variant="h6" gutterBottom>
                Évaluation de Compétences
              </Typography>
            </Box>
            <Typography variant="body2">
              Testez vos compétences techniques et non techniques.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              disabled={isFreePlan}
            >
              <Link
                to="/evaluation"
                style={{ color: "inherit", textDecoration: "none" }}
                onClick={handleEvaluationClick}
              >
                Évaluer
              </Link>
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            sx={{
              p: 2,
              transition: "transform 0.3s, box-shadow 0.3s",
              "&:hover": { transform: "scale(1.05)", boxShadow: 6 },
            }}
            elevation={3}
          >
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <RestoreIcon sx={{ mr: 1 }} />
              <Typography variant="h6" gutterBottom>
                Évaluations Passées
              </Typography>
            </Box>
            <Typography variant="body2">
              Consultez les résultats de vos évaluations passées.
            </Typography>
            <Box
              sx={{
                maxHeight: 200,
                overflowY: "auto",
                mt: 2,
              }}
            >
              {pastEvaluations.length > 0 ? (
                pastEvaluations.map((evaluation, index) => (
                  <Box key={index} sx={{ mt: 2 }}>
                    <Typography variant="body2">
                      Date:{" "}
                      {new Date(evaluation.timestamp).toLocaleDateString()} -
                      Score: {evaluation.score}/10
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography variant="body2" sx={{ mt: 2 }}>
                  Aucun résultat d'évaluation passé.
                </Typography>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Informations de carrière manquantes"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Veuillez renseigner d'abord vos informations de carrière dans le menu profil.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Fermer
          </Button>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            Aller au profil
          </Button>
        </DialogActions>
      </Dialog>
      
    </Box>
  );
}

export default Preparation;

// Import des modules Firebase nécessaires
import { initializeApp, getApps } from "firebase/app";
import {
  getFirestore,
  collection,
  query,
  where,
  deleteDoc,
  getDocs,
} from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth"; // Importer GoogleAuthProvider
import { getStorage } from "firebase/storage"; // Importer Firebase Storage

// Import des modules Stripe nécessaires
import { loadStripe } from "@stripe/stripe-js";
import { getFunctions, httpsCallable } from "firebase/functions";

// Configuration Firebase
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET, // Important for Firebase Storage
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialisation de l'application Firebase
const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app); // Initialisation de Firebase Storage

// Initialisation des fonctions Firebase
const functions = getFunctions(app);

// Configuration du fournisseur Google
const googleProvider = new GoogleAuthProvider();

// Configuration Stripe
const stripePromise = loadStripe("pk_test_Ic8RO9jf5LAF6lXgCExF2jXX");

// Fonction pour vider les guides existants de l'utilisateur
const deleteUserGuidesFromFirestore = async (userId) => {
  try {
    const guidesCollection = collection(db, "guides");

    // Obtenir les guides existants de l'utilisateur
    const querySnapshot = await getDocs(
      query(guidesCollection, where("userId", "==", userId))
    );

    // Supprimer les guides existants
    querySnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
    });

    console.log("Guides deleted from Firestore successfully.");
  } catch (error) {
    console.error("Error deleting guides from Firestore:", error);
  }
};

// Fonction pour créer un Payment Intent
const createPaymentIntent = async (amount) => {
  try {
    const createPaymentIntentFunction = httpsCallable(functions, "createPaymentIntent");
    const { data } = await createPaymentIntentFunction({ amount });
    return data.clientSecret;
  } catch (error) {
    console.error("Error creating Payment Intent:", error);
    throw error;
  }
};

export {
  app,
  db,
  auth,
  storage, // Export Firebase Storage to use it in other components
  googleProvider,
  stripePromise,
  deleteUserGuidesFromFirestore,
  createPaymentIntent,
};

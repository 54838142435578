import React, { useState, useEffect } from "react";
import { fetchUserSubscriptions } from "../fetchUserSubscriptions"; // Import depuis le même répertoire
import { CircularProgress, Typography } from "@mui/material";
import { analyzeAndUpdateInterviewFeedback } from "../api/fetchOpenAiData";

const Test2 = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [latestSubscription, setLatestSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const initializeData = async () => {
      try {
        // Exécutez d'abord analyzeAndUpdateInterviewFeedback
        await analyzeAndUpdateInterviewFeedback();

        // Ensuite, récupérez les abonnements
        const subscriptions = await fetchUserSubscriptions(setCurrentUser);
        if (subscriptions.length > 0) {
          setLatestSubscription(subscriptions[0]);
        }
        setLoading(false);
      } catch (err) {
        console.error("Error during initialization:", err);
        setError("Une erreur s'est produite lors de l'initialisation.");
        setLoading(false);
      }
    };

    initializeData();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <div>
      {latestSubscription ? (
        <div>
          <Typography variant="h6">Dernier Abonnement</Typography>
          <Typography variant="body1">Produit: {latestSubscription.name}</Typography>
          <Typography variant="body1">Statut: {latestSubscription.status}</Typography>
        </div>
      ) : (
        <Typography variant="body1">
          Aucun abonnement trouvé pour l'utilisateur connecté.
        </Typography>
      )}
    </div>
  );
};

export default Test2;

// CheckoutSingleSubscription.js
import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth } from "firebase/auth";

const stripePromise = loadStripe("pk_test_Ic8RO9jf5LAF6lXgCExF2jXX");

const CheckoutSingleSubscription = () => {
  const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        const functions = getFunctions();
        const createCheckoutSession = httpsCallable(
          functions,
          "createCheckoutSession"
        );

        const { data } = await createCheckoutSession({
          priceId: "price_1PfMXtEgIl7YTQHKPdFpuzMW",
        });

        setClientSecret(data.clientSecret);
        setLoading(false);
      } catch (error) {
        console.error("Error creating checkout session:", error);
        setLoading(false);
      }
    };

    fetchClientSecret();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!clientSecret) {
    return <div>Error loading checkout session. Please try again.</div>;
  }

  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <CheckoutForm />
    </Elements>
  );
};

const CheckoutForm = () => {
  const handleSubmit = async (event) => {
    event.preventDefault();

    const { error } = await stripe.redirectToCheckout({
      clientSecret: clientSecret,
    });

    if (error) {
      console.error("Error redirecting to checkout:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <button type="submit">Subscribe</button>
    </form>
  );
};

export default CheckoutSingleSubscription;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../authContext";
import { db } from "../firebaseConfig";
import {
  doc,
  getDocs,
  updateDoc,
  collection,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import {
  Container,
  Paper,
  Typography,
  Snackbar,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const API_URL = "https://api.openai.com/v1/completions";
const API_KEY = "sk-proj-muqJt2KmzH4toeHGdCE8T3BlbkFJNxiJP3Tqn3RDt0iNOLJy";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const sendToOpenAI = async (prompt) => {
  try {
    const response = await axios.post(
      API_URL,
      {
        model: "gpt-3.5-turbo-instruct",
        prompt: prompt,
        temperature: 1,
        max_tokens: 2000,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
      },
      {
        headers: {
          Authorization: `Bearer ${API_KEY}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.choices[0].text.trim();
  } catch (error) {
    throw new Error("Erreur lors de la récupération des données depuis OpenAI");
  }
};

const isArray = (value) => {
  return Array.isArray(value);
};

const FeedbackSummary = () => {
  const { currentUser } = useAuth();
  const [simulationData, setSimulationData] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLatestSimulation = async () => {
      try {
        const simulationsCollection = collection(db, "simulations");
        const q = query(
          simulationsCollection,
          where("userid", "==", currentUser.email),
          where("status", "==", "Terminé"),
          orderBy("timestamp", "desc"),
          limit(1)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const latestSimulationDoc = querySnapshot.docs[0];
          setSimulationData({
            ...latestSimulationDoc.data(),
            id: latestSimulationDoc.id,
          });

          await analyzeFeedbacks(
            latestSimulationDoc.data(),
            latestSimulationDoc.id
          );
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des données de simulation :",
          error
        );
        setLoading(false);
      }
    };

    if (currentUser) {
      fetchLatestSimulation();
    }
  }, [currentUser]);

  const analyzeFeedbacks = async (simulationData, simulationId) => {
    if (!simulationData) return;

    const prompt = `
      Analyse les feedbacks et ratings suivants et donne le résultat au format JSON :
      ${simulationData.detail
        .map(
          (item, index) =>
            `Question ${index + 1} : ${item.question}
          Réponse : ${item.response}
          Rating : ${item.rating}`
        )
        .join("\n")}
        
      Exemple de résultat attendu :
      {
        "pointsforts": ["point fort 1", "point fort 2"],
        "pointsaameliorer": ["point à améliorer 1", "point à améliorer 2"],
        "globalScore": 0
      }
    `;

    try {
      const analysis = await sendToOpenAI(prompt);

      let parsedAnalysis;
      try {
        parsedAnalysis = JSON.parse(analysis);
      } catch (jsonError) {
        console.error("Erreur lors de l'analyse du JSON :", jsonError);
        setAlertMessage(
          "Erreur lors de l'analyse des feedbacks : JSON invalide !"
        );
        setOpenSnackbar(true);
        setLoading(false);
        return;
      }

      const updatedData = {
        ...simulationData,
        pointsforts: parsedAnalysis.pointsforts,
        pointsaameliorer: parsedAnalysis.pointsaameliorer,
        globalScore: parsedAnalysis.globalScore,
      };

      updatedData.globalScore = Math.min(
        10,
        Math.max(1, updatedData.globalScore)
      );

      const resultat = updatedData.globalScore > 5 ? "Réussi" : "Échec";

      const simulationDocRef = doc(db, "simulations", simulationId);
      await updateDoc(simulationDocRef, {
        pointsforts: updatedData.pointsforts,
        pointsaameliorer: updatedData.pointsaameliorer,
        globalScore: updatedData.globalScore,
        Resultat: resultat,
      });

      setSimulationData(updatedData);
      setAlertMessage("Analyse réussie et données mises à jour !");
      setOpenSnackbar(true);
      setLoading(false);
    } catch (error) {
      console.error("Erreur lors de l'analyse des feedbacks :", error);
      setAlertMessage("Erreur lors de l'analyse des feedbacks !");
      setOpenSnackbar(true);
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container>
      <Paper sx={{ p: 2, mt: 3 }}>
        <Typography variant="h5" gutterBottom>
          Résumé de la simulation :{" "}
          {simulationData?.title || "Titre de la simulation"}
        </Typography>
        <Typography variant="body1">
          Score global : {simulationData?.globalScore || "XX"}
        </Typography>
        <Typography variant="body1">
          Résultat : {simulationData?.Resultat || "En attente"}
        </Typography>
        <Typography variant="h6" gutterBottom>
          Points forts :
        </Typography>
        <List>
          {(isArray(simulationData?.pointsforts)
            ? simulationData.pointsforts
            : ["Compétence 1", "Compétence 2"]
          ).map((point, idx) => (
            <ListItem key={idx}>
              <ListItemText primary={`- ${point}`} />
            </ListItem>
          ))}
        </List>
        <Typography variant="h6" gutterBottom>
          Points à améliorer :
        </Typography>
        <List>
          {(isArray(simulationData?.pointsaameliorer)
            ? simulationData.pointsaameliorer
            : ["Compétence 1", "Compétence 2"]
          ).map((point, idx) => (
            <ListItem key={idx}>
              <ListItemText primary={`- ${point}`} />
            </ListItem>
          ))}
        </List>
        {loading && <CircularProgress />}
      </Paper>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          {alertMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default FeedbackSummary;

import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import {
  Box,
  Typography,
  Avatar,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  IconButton,
  LinearProgress,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import googleConfig from "../api/googleconfig";
import axios from "axios";
import { getAuth } from "firebase/auth";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  Timestamp,
} from "firebase/firestore";
import { db } from "../firebaseConfig";
import { analyzeSimulationFeedback } from "../api/fetchOpenAiData"; // Assurez-vous de mettre le bon chemin vers votre fichier de fonctions

const avatarUrl = "https://jobjourney.groovi-app.com/images/avatare_recru.png";

const SimulationInterview = () => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [response, setResponse] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [isRecording, setIsRecording] = useState(false); // Assure que l'icône est barrée au démarrage
  const [isProcessing, setIsProcessing] = useState(false);
  const [instructionsDialogOpen, setInstructionsDialogOpen] = useState(true);
  const [simulationTitle, setSimulationTitle] = useState("");
  const [simulationId, setSimulationId] = useState(null);
  const mediaRecorderRef = useRef(null);
  const streamRef = useRef(null);
  const audioRef = useRef(new Audio());
  const audioChunksRef = useRef([]);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // Ajout de l'état de soumission
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [feedback, setFeedback] = useState("");

  const API_URL = "https://api.openai.com/v1/completions";
  const API_KEY = "sk-proj-muqJt2KmzH4toeHGdCE8T3BlbkFJNxiJP3Tqn3RDt0iNOLJy";

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  // Fonction pour envoyer une requête à OpenAI
  const sendToOpenAI = async (prompt) => {
    try {
      const response = await axios.post(
        API_URL,
        {
          model: "gpt-3.5-turbo-instruct",
          prompt: prompt,
          temperature: 1,
          max_tokens: 1000,
          top_p: 1,
          frequency_penalty: 0,
          presence_penalty: 0,
        },
        {
          headers: {
            Authorization: `Bearer ${API_KEY}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data.choices[0].text;
    } catch (error) {
      throw new Error(
        "Erreur lors de la récupération des données depuis OpenAI"
      );
    }
  };

  const evaluateResponse = async (question, response) => {
    const prompt = `Évaluez cette réponse à une question d'entretien.\n\nQuestion: ${question}\nRéponse: ${response}\n\nDonnez une note de 0 à 10 pour la qualité de la réponse et fournissez un bref commentaire.`;
    const evaluation = await sendToOpenAI(prompt);

    const ratingMatch = evaluation.match(/Note:\s*(\d+)/);
    const rating = ratingMatch ? parseInt(ratingMatch[1], 10) : null;
    const feedback = evaluation.replace(/Note:\s*\d+/, "").trim();

    return { rating, feedback };
  };

  useEffect(() => {
    const fetchGeneratedSimulations = async () => {
      const auth = getAuth();
      const currentUser = auth.currentUser;

      if (!currentUser) {
        throw new Error("Utilisateur non connecté");
      }

      const simulationsCollection = collection(db, "simulations");
      const q = query(
        simulationsCollection,
        where("userid", "==", currentUser.email),
        where("status", "==", "Généré")
      );
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const firstSimulationDoc = querySnapshot.docs[0];
        const firstSimulation = firstSimulationDoc.data();
        setSimulationTitle(firstSimulation.title);
        setQuestions(firstSimulation.detail || []);
        setSimulationId(firstSimulationDoc.id);

        // Mise à jour du statut à 'Encours'
        const simulationDocRef = doc(db, "simulations", firstSimulationDoc.id);
        await updateDoc(simulationDocRef, {
          status: "Encours",
        });
      }
    };

    fetchGeneratedSimulations();
  }, []);


  useEffect(() => {
    if (questions.length > 0 && !instructionsDialogOpen) {
      speak(questions[currentQuestionIndex].question);
    }
  }, [questions, currentQuestionIndex, instructionsDialogOpen]);

  // Message audio demarrage simulation
    useEffect(() => {
    if (instructionsDialogOpen) {
      speak(
        "La simulation va commencer. Pour répondre, écrivez dans la zone de texte ou utilisez le microphone. Activez l'icône pour parler et désactivez-la pour terminer."
      );
    }
  }, [instructionsDialogOpen]);

  useEffect(() => {
    return () => {
      stopRecordingAndReleaseStream();
    };
  }, []);
// enregistrement reponse audio -------------------

const startRecording = async () => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    
    // Configuration de MediaRecorder
    streamRef.current = stream;
    mediaRecorderRef.current = new MediaRecorder(stream, { mimeType: 'audio/webm; codecs=opus' });
    audioChunksRef.current = [];

    mediaRecorderRef.current.ondataavailable = (event) => {
      audioChunksRef.current.push(event.data);
    };

    mediaRecorderRef.current.onstop = async () => {
      setIsProcessing(true);
      const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
      const audioBase64 = await blobToBase64(audioBlob);

      try {
        const response = await axios.post(`https://speech.googleapis.com/v1p1beta1/speech:recognize?key=${googleConfig.apiKey}`, {
          config: {
            encoding: 'WEBM_OPUS',
            sampleRateHertz: 48000,
            languageCode: 'fr-FR',
            audioChannelCount: 1,
          },
          audio: { content: audioBase64.split(',')[1] },
        });

        if (response.data.results && response.data.results.length > 0) {
          const transcription = response.data.results.map(result => result.alternatives[0].transcript).join('\n') || 'Transcription non disponible';
          setResponse(transcription);
        } else {
          setResponse('Aucune transcription trouvée.');
        }
      } catch (error) {
        console.error('Erreur lors de la transcription audio:', error.response?.data?.error?.message || error.message);
        setResponse(`Erreur lors de la transcription audio: ${error.response?.data?.error?.message || error.message}`);
      } finally {
        setIsProcessing(false);
      }
    };

    mediaRecorderRef.current.start();
    setIsRecording(true);
  } catch (error) {
    if (error.name === 'NotAllowedError') {
      console.error('Autorisation refusée pour accéder au microphone');
      // Affichez un message convivial à l'utilisateur ou invitez-le à activer les autorisations
    } else {
      console.error('Erreur lors de l\'accès aux dispositifs multimédia:', error);
    }
  }
};


// --------------------------- Fin enregistrement audio --------------

// Ajoutez un gestionnaire d'événements pour gérer des enregistrements plus longs
const handleLongRecording = () => {
  if (mediaRecorderRef.current.state === "recording") {
    mediaRecorderRef.current.stop();
    setTimeout(() => {
      startRecording(); // redémarre l'enregistrement après un court délai
    }, 1000);
  }
};

  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
    }
    setIsRecording(false);
  };

  const stopRecordingAndReleaseStream = () => {
    stopRecording();
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
    }
  };

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  //-------------- Fonction speak ------------

  const speak = (text) => {
    const requestBody = {
      input: { text: text },
      voice: { languageCode: "fr-FR", ssmlGender: "MALE" },
      audioConfig: { audioEncoding: "MP3" },
    };
  
    fetch(`https://texttospeech.googleapis.com/v1/text:synthesize?key=${googleConfig.apiKey}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then(response => response.json())
      .then(data => {
        if (data.audioContent) {
          const audioBase64 = `data:audio/mp3;base64,${data.audioContent}`;
          
          // Arrêter la lecture en cours
          if (audioRef.current.src) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
          }
  
          // Charger et jouer le nouveau fichier
          audioRef.current.src = audioBase64;
          audioRef.current.load(); // Assurez-vous que le fichier est chargé avant de jouer
          audioRef.current.play()
            .catch(error => console.error("Erreur lors de la lecture de l'audio:", error));
        }
      })
      .catch(error => console.error("Erreur avec l'API Text-to-Speech:", error));
  };
  

  //-------------- Fin fonction speak -------------

  const handleResponseChange = (event) => {
    setResponse(event.target.value);
  };

  const handleSubmit = async () => {
    if (response.trim() === "") {
      setErrorDialogOpen(true);
    } else {
      setIsSubmitting(true); // Démarre le sablier
      try {
        const updatedQuestions = [...questions];
        const currentQuestion = updatedQuestions[currentQuestionIndex];

        currentQuestion.response = response;

        const simulationDocRef = doc(db, "simulations", simulationId);
        await updateDoc(simulationDocRef, {
          detail: updatedQuestions,
        });

        const evaluation = await evaluateResponse(
          currentQuestion.question,
          response
        );
        currentQuestion.rating = evaluation.rating;
        currentQuestion.feedback = evaluation.feedback;
        setFeedback(evaluation.feedback);

        await updateDoc(simulationDocRef, {
          detail: updatedQuestions,
        });

        setSnackbarOpen(true);

        setResponse("");
        if (currentQuestionIndex < questions.length - 1) {
          setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
          await analyzeSimulationFeedback(simulationId);

          // Mise à jour du statut à 'Terminé'
          await updateDoc(simulationDocRef, {
            status: "Terminé",
          });

          setOpenDialog(true);
        }
      } catch (error) {
        console.error("Erreur lors de la soumission:", error);
      } finally {
        setIsSubmitting(false); // Arrête le sablier
      }
    }
  };

  const handleSkip = async () => {
    const updatedQuestions = [...questions];
    updatedQuestions[currentQuestionIndex].response = "Question ignorée";
    setResponse("");
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      await analyzeSimulationFeedback(simulationId); // Appelez la fonction ici aussi
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleErrorDialogClose = () => {
    setErrorDialogOpen(false);
  };

  const handleInstructionsDialogClose = () => {
    setInstructionsDialogOpen(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Dialog
        open={instructionsDialogOpen}
        onClose={handleInstructionsDialogClose}
      >
        <DialogTitle>Instructions</DialogTitle>
        <DialogContent>
          <DialogContentText>
          La simulation va commencer. Pour répondre, écrivez dans la zone de texte ou utilisez le microphone. 
          Activez l'icône pour parler et désactivez-la pour terminer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleInstructionsDialogClose}>Commencer</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Fin de la simulation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Vous avez complété toutes les questions de cette simulation.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <NavLink to="/feedbacksummary" style={{ textDecoration: "none" }}>
            <Button onClick={handleCloseDialog}>Fermer</Button>
          </NavLink>
        </DialogActions>
      </Dialog>
      <Dialog open={errorDialogOpen} onClose={handleErrorDialogClose}>
        <DialogTitle>Erreur</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Vous devez fournir une réponse avant de passer à la question
            suivante.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorDialogClose}>Fermer</Button>
        </DialogActions>
      </Dialog>

      <Box display="flex" justifyContent="center" alignItems="center" mb={4}>
        <Avatar
          alt="Interview Avatar"
          src={avatarUrl}
          sx={{ width: 100, height: 100 }}
        />
      </Box>

      <Typography variant="h5" align="center" gutterBottom>
        {simulationTitle}
      </Typography>

      {questions.length > 0 && (
        <Box>
          <LinearProgress
            variant="determinate"
            value={((currentQuestionIndex + 1) / questions.length) * 100}
            sx={{ marginBottom: 2 }}
          />
          <Typography variant="h6" align="center" gutterBottom>
            Question {currentQuestionIndex + 1}:{" "}
            {questions[currentQuestionIndex].question}
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={response}
            onChange={handleResponseChange}
            disabled={isProcessing}
          />
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={2}
          >
            <IconButton onClick={isRecording ? stopRecording : startRecording}>
              {isRecording ? (
                <MicIcon color="error" />
              ) : (
                <MicOffIcon color="primary" />
              )}
            </IconButton>
          </Box>
          {isProcessing && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={2}
            >
              <CircularProgress />
            </Box>
          )}
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button variant="outlined" onClick={handleSkip}>
              Ignorer
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Soumettre"
              )}
            </Button>
          </Box>
        </Box>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {feedback}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SimulationInterview;

import React from "react";
import { Box, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom"; // Importer Link de react-router-dom

function Footer() {
  return (
    <Box sx={{ p: 2, mt: 'auto', backgroundColor: '#f5f5f5', borderTop: '1px solid #ccc' }}>
      <Typography variant="body2" color="textSecondary" align="center">
        &copy; {new Date().getFullYear()} JobJourney. Tous droits réservés.
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        <RouterLink to="/ConditionsPdfViewer" style={{ textDecoration: 'none', color: 'inherit' }}>
          Conditions d'utilisation
        </RouterLink>{" "}
        |{" "}
        <RouterLink to="/PolitiqueDeConfidentialite" style={{ textDecoration: 'none', color: 'inherit' }}>
          Politique de confidentialité
        </RouterLink>{" "}
        |{" "}
        <RouterLink to="/contact" style={{ textDecoration: 'none', color: 'inherit' }}>
          Contact
        </RouterLink>
      </Typography>
    </Box>
  );
}

export default Footer;

import axios from "axios";
import { db } from "../firebaseConfig";
import { doc, getDoc, collection, addDoc,updateDoc,query, where,getDocs   } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid"; // Importation correcte de uuidv4
import { getAuth } from "firebase/auth";

const API_URL = "https://api.openai.com/v1/completions";
const API_KEY = "sk-proj-muqJt2KmzH4toeHGdCE8T3BlbkFJNxiJP3Tqn3RDt0iNOLJy";

// Fonction pour envoyer une requête à OpenAI--------------------------

const sendToOpenAI = async (prompt) => {
  try {
    const response = await axios.post(
      API_URL,
      {
        model: "gpt-3.5-turbo-instruct",
        prompt: prompt,
        temperature: 1,
        max_tokens: 2000,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
      },
      {
        headers: {
          Authorization: `Bearer ${API_KEY}`,
          "Content-Type": "application/json",
        },
      }
    );

    console.log("Réponse complète d'OpenAI:", response.data);

    if (
      response.data &&
      response.data.choices &&
      response.data.choices.length > 0 &&
      response.data.choices[0].text
    ) {
      return response.data.choices[0].text.trim();
    } else {
      throw new Error("Réponse OpenAI vide ou mal formée");
    }
  } catch (error) {
    console.error("Erreur lors de la récupération des données depuis OpenAI:", error);
    throw new Error("Erreur lors de la récupération des données depuis OpenAI");
  }
};


// ---------------- Fin fon open Ai -----------------------------


// Fonction pour récupérer les données utilisateur
export const fetchOpenAiData = async (currentUser) => {
  if (!currentUser) return null;

  try {
    const userDoc = doc(db, "Users", currentUser.email);
    const userSnapshot = await getDoc(userDoc);
    if (userSnapshot.exists()) {
      return userSnapshot.data();
    }
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des données utilisateur :",
      error
    );
  }

  return null;
};

// Fonction pour ajouter des guides à Firestore
const addGuidesToFirestore = async (guides) => {
  try {
    const guidesCollection = collection(db, "guides");

    for (const guide of guides) {
      await addDoc(guidesCollection, {
        id: uuidv4(),
        userId: guide.userId,
        l_guide: guide.l_guide.slice(0, 3), // Assurez-vous qu'il n'y a pas plus de 3 éléments
      });
    }
  } catch (error) {
    console.error("Erreur lors de l'ajout des guides à Firestore :", error);
  }
};

// Fonction pour générer et stocker les guides
export const generateAndStoreGuides = async (userData, currentUser) => {
  if (!userData || !currentUser) return;

  try {
    const prompt = `
      Créez un seul guide sous forme de conseils de préparation pour un entretien d'embauche. Voici les détails de l'utilisateur :
      - Objectifs de carrière : ${userData.careerGoals}
      - Secteurs d'intérêt : ${userData.interestSectors.join(", ")}
      - Compétences : ${userData.skills.join(", ")}
      - Expérience :
        ${userData.experiences
          .map(
            (exp) =>
              `- Entreprise : ${exp.company}, Durée : ${exp.duration}, Poste : ${exp.position}`
          )
          .join("\n    ")}
      
        Veuillez fournir le guide dans le format JSON suivant et limitez chaque description à 100 caractères :
      {
        "id": "Identifiant unique",
        "userId": "${currentUser.email}",
        "l_guide": [
          {
            "description": "guide sous forme de conseil (1000 caractères maximum)",
            "title": "Titre du guide 1"
          },
          {
            "description": "guide sous forme de conseil (1000 caractères maximum)",
            "title": "Titre du guide 2"
          },
          {
            "description": "guide sous forme de conseil (1000 caractères maximum)",
            "title": "Titre du guide 3"
          }
        ]
      }
    `;

    const guidesData = await sendToOpenAI(prompt);
    console.log("Réponse brute de l'API OpenAI :", guidesData);

    // Vérifiez que guidesData est bien une chaîne de caractères JSON parsable
    if (typeof guidesData !== "string") {
      throw new TypeError(
        "Le texte de la réponse de OpenAI n'est pas une chaîne de caractères JSON valide."
      );
    }

    let guide;
    try {
      guide = JSON.parse(guidesData);
    } catch (jsonError) {
      console.error("Erreur de parsing JSON:", jsonError);
      console.log("Réponse OpenAI non valide:", guidesData);
      throw new Error("Erreur de parsing JSON");
    }

    // Vérifiez que guide est un objet avant de continuer
    if (typeof guide !== "object" || guide === null) {
      throw new TypeError("guideData n'est pas un objet valide");
    }

    // Limiter le nombre d'éléments dans l_guide à 3
    guide.l_guide = guide.l_guide.slice(0, 3);

    await addGuidesToFirestore([guide]);
    return guide;
  } catch (error) {
    console.error(
      "Erreur lors de la génération et du stockage des guides :",
      error
    );
    if (error.response) {
      console.error("Data:", error.response.data);
      console.error("Status:", error.response.status);
      console.error("Headers:", error.response.headers);
    } else if (error.request) {
      console.error("Request:", error.request);
    } else {
      console.error("Error message:", error.message);
    }
    throw error;
  }
};

// Fonction pour ajouter des questions d'interview à Firestore
const addInterviewQuestionsToFirestore = async (questions) => {
  try {
    const trimmedQuestions = questions.trim();
    let questionsArray;

    // Vérifier si trimmedQuestions est une chaîne JSON valide
    try {
      questionsArray = JSON.parse(trimmedQuestions);
    } catch (error) {
      console.error("Erreur de parsing JSON :", error);
      return;
    }

    // Assurez-vous que questionsArray est un tableau
    if (!Array.isArray(questionsArray)) {
      console.error("Les données JSON ne sont pas un tableau.");
      return;
    }

    const questionsCollection = collection(db, "interviewQuestions");

    for (const question of questionsArray) {
      // Assurez-vous que chaque question a les champs requis
      if (question.id && question.userId && question.l_question) {
        await addDoc(questionsCollection, question);
      } else {
        console.warn("Question invalide : ", question);
      }
    }
  } catch (error) {
    console.error(
      "Erreur lors de l'ajout des questions d'interview à Firestore :",
      error
    );
  }
};

// Fonction pour générer et stocker les questions d'interview
export const generateAndStoreInterviewQuestions = async (
  userData,
  currentUser
) => {
  if (!userData || !currentUser) return;

  try {
    const prompt = `
      Créez des questions d'entretien pour un entretien d'embauche. Voici les détails de l'utilisateur :
      - Objectifs de carrière : ${userData.careerGoals}
      - Secteurs d'intérêt : ${userData.interestSectors.join(", ")}
      - Compétences : ${userData.skills.join(", ")}
      - Expériences :
        ${userData.experiences
          .map(
            (exp) =>
              `- Entreprise : ${exp.company}, Durée : ${exp.duration}, Poste : ${exp.position}`
          )
          .join("\n    ")}
      
      Veuillez fournir chaque question dans le format JSON suivant :
      [{
        "id": "Identifiant unique",
        "userId": "${currentUser.email}",
        "l_question": [
          {
            "question": "Intitulé de la question 1",
            "answer": "Réponse à la question 1"
          },
          {
            "question": "Intitulé de la question 2",
            "answer": "Réponse à la question 2"
          },
          {
            "question": "Intitulé de la question 3",
            "answer": "Réponse à la question 3"
          },
          {
            "question": "Intitulé de la question 4",
            "answer": "Réponse à la question 4"
          },
          {
            "question": "Intitulé de la question 5",
            "answer": "Réponse à la question 5"
          }
        ]
      }]
    `;
    const questionsData = await sendToOpenAI(prompt);
    await addInterviewQuestionsToFirestore(questionsData);
    return questionsData;
  } catch (error) {
    console.error(
      "Erreur lors de la génération et du stockage des questions d'interview :",
      error
    );
    throw error;
  }
};

// Fonction pour ajouter des simulations à Firestore
const addSimulationsToFirestore = async (simulation) => {
  try {
    const simulationsCollection = collection(db, "simulations");
    await addDoc(simulationsCollection, simulation);
  } catch (error) {
    console.error(
      "Erreur lors de l'ajout des simulations à Firestore :",
      error
    );
  }
};

// Fonction pour générer et stocker les simulations ----------------------------------------------

export const generateAndStoreSimulations = async (userData, currentUser) => {
  if (!userData || !currentUser) return;

  try {
    // Vérification des données utilisateur
    if (!userData.careerGoals || !userData.interestSectors || !userData.skills) {
      throw new Error("Les données utilisateur sont incomplètes.");
    }

    const prompt = `
      Créez une simulation d'interview creative et moins monotone, avec des questions technique et non technique. Voici les détails de l'utilisateur :
      - Objectifs de carrière : ${userData.careerGoals}
      - Secteurs d'intérêt : ${userData.interestSectors.join(", ")}
      - Compétences : ${userData.skills.join(", ")}
      - Expériences :
         ${userData.experiences
           .map(
             (exp) =>
               `- Entreprise : ${exp.company}, Durée : ${exp.duration}, Poste : ${exp.position}`
           )
           .join("\n    ")}

      Veuillez fournir la simulation dans le format JSON suivant :
      {
        "title": "Titre de la simulation",
        "id": "Identifiant unique",
        "userid": "${currentUser.email}",
        "timestamp": "Date du jour",
        "status": "Généré",
        "globaleScore": 0,
        "pointsaameliorer": "",
        "pointsforts": "",
        "Resultat": "",
        "detail": [
          { "question": "Question 1" },
          { "question": "Question 2" },
          { "question": "Question 3" },
          { "question": "Question 4" },
          { "question": "Question 5" },
          { "question": "Question 6" },
          { "question": "Question 7" },
          { "question": "Question 8" },
          { "question": "Question 9" },
          { "question": "Question 10" }
        ]
      }
      Chaque objet dans le tableau 'detail' doit contenir uniquement le champ 'question' avec une question textuelle.
    `;

    console.log("Prompt envoyé à OpenAI:", prompt);

    const openAiResponse = await sendToOpenAI(prompt);
    console.log("Réponse brute d'OpenAI:", openAiResponse);

    // Validation de la réponse OpenAI pour s'assurer qu'elle est un JSON valide
    let simulationData;
    try {
      simulationData = JSON.parse(openAiResponse);
    } catch (jsonError) {
      console.error("Erreur de parsing JSON:", jsonError);
      console.log("Réponse OpenAI non valide:", openAiResponse);
      throw new Error("Erreur de parsing JSON");
    }

    // Affichage des données juste après le parsing JSON
    console.log("Données de simulation après parsing JSON:", simulationData);

    // Vérification des données de simulation
    if (!simulationData.title) {
      console.error("Le titre de la simulation est manquant ou incorrect :", simulationData.title);
    }
    if (!simulationData.id) {
      console.error("L'ID de la simulation est manquant ou incorrect :", simulationData.id);
    }
    if (!simulationData.userid) {
      console.error("L'UserID de la simulation est manquant ou incorrect :", simulationData.userid);
    }
    if (!simulationData.timestamp) {
      console.error("Le timestamp de la simulation est manquant ou incorrect :", simulationData.timestamp);
    }
    if (!simulationData.status) {
      console.error("Le status de la simulation est manquant ou incorrect :", simulationData.status);
    }
    if (!Array.isArray(simulationData.detail) || simulationData.detail.length === 0) {
      console.error("Le détail de la simulation est manquant ou incorrect :", simulationData.detail);
    }

    if (
      !simulationData.title || 
      !simulationData.id ||
      !simulationData.userid ||
      !simulationData.timestamp ||
      !simulationData.status ||
      !Array.isArray(simulationData.detail) ||
      simulationData.detail.length === 0
    ) {
      throw new Error("Les données de simulation sont invalides ou non définies.");
    }

    // Ajouter les champs supplémentaires nécessaires à chaque question
    simulationData.detail = simulationData.detail.map((question) => ({
      ...question,
      response: "",
      feedback: "",
      rating: 0
    }));

    // Ajout des champs supplémentaires nécessaires
    simulationData.id = uuidv4();
    simulationData.timestamp = new Date().toISOString();
    simulationData.userid = currentUser.email;
    simulationData.globaleScore = 0;
    simulationData.pointsaameliorer = "";
    simulationData.pointsforts = "";
    simulationData.Resultat = "";

    await addSimulationsToFirestore(simulationData);
    console.log("Simulation stockée dans Firestore:", simulationData);

    return simulationData;
  } catch (error) {
    console.error("Erreur lors de la génération et du stockage des simulations :", error.message);
    throw error;
  }
};



//-Fin generation simulation --------------------------------------------------------------------


// Fonction pour générer et stocker les quiz
export const generateAndStoreQuiz = async (userData, currentUser) => {
  if (!userData || !currentUser) return;

  // Fonction pour mélanger les éléments d'un tableau
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  try {
    const prompt = `
      Veuillez créer un quiz composé de 10 questions créatives et non monotones, techniques et non techniques, adaptées à l'utilisateur suivant. Voici ses informations :
      - Objectifs de carrière : ${userData.careerGoals}
      - Secteurs d'intérêt : ${userData.interestSectors.join(", ")}
      - Compétences : ${userData.skills.join(", ")}
      - Expériences :
        ${userData.experiences
          .map(
            (exp) =>
              `- Entreprise : ${exp.company}, Durée : ${exp.duration}, Poste : ${exp.position}`
          )
          .join("\n    ")}
      
      Le quiz doit être fourni au format JSON suivant. Assurez-vous que chaque bonne réponse figure parmi les options proposées :
      {
        "id": "Identifiant unique",
        "userid": "${currentUser.email}",
        "timestamp": "${new Date().toISOString()}",
        "status": "Généré",
        "score": 0,
        "l_quiz": [
          {
            "question": "Intitulé de la question 1",
            "answer": "Réponse correcte à la question 1",
            "options": ["Option 1", "Option 2"]
          },
          {
            "question": "Intitulé de la question 2",
            "answer": "Réponse correcte à la question 2",
            "options": ["Option 1", "Option 2", "Option 3"]
          },
          {
            "question": "Intitulé de la question 3",
            "answer": "Réponse correcte à la question 3",
            "options": ["Option 1", "Option 2", "Option 3", "Option 4"]
          },
          {
            "question": "Intitulé de la question 4",
            "answer": "Réponse correcte à la question 4",
            "options": ["Option 1", "Option 2"]
          },
          {
            "question": "Intitulé de la question 5",
            "answer": "Réponse correcte à la question 5",
            "options": ["Option 1", "Option 2", "Option 3"]
          },
          {
            "question": "Intitulé de la question 6",
            "answer": "Réponse correcte à la question 6",
            "options": ["Option 1", "Option 2"]
          },
          {
            "question": "Intitulé de la question 7",
            "answer": "Réponse correcte à la question 7",
            "options": ["Option 1", "Option 2", "Option 3", "Option 4"]
          },
          {
            "question": "Intitulé de la question 8",
            "answer": "Réponse correcte à la question 8",
            "options": ["Option 1", "Option 2"]
          },
          {
            "question": "Intitulé de la question 9",
            "answer": "Réponse correcte à la question 9",
            "options": ["Option 1", "Option 2", "Option 3"]
          },
          {
            "question": "Intitulé de la question 10",
            "answer": "Réponse correcte à la question 10",
            "options": ["Option 1", "Option 2", "Option 3", "Option 4"]
          }
        ]
      }
    `;

    const quizData = await sendToOpenAI(prompt);

    if (typeof quizData !== "string") {
      throw new TypeError("Le texte de la réponse de OpenAI n'est pas une chaîne de caractères JSON valide.");
    }

    let parsedQuizData;
    try {
      parsedQuizData = JSON.parse(quizData);

      if (!parsedQuizData.l_quiz || !Array.isArray(parsedQuizData.l_quiz)) {
        throw new Error("Le quiz généré ne contient pas de liste de questions valide.");
      }

      parsedQuizData.l_quiz.forEach((quizItem) => {
        if (!quizItem.options.includes(quizItem.answer)) {
          quizItem.options.push(quizItem.answer);
        }
        quizItem.options = shuffleArray(quizItem.options); // Mélanger les options
      });

    } catch (parseError) {
      console.error("Erreur lors de l'analyse du JSON généré :", parseError);
      throw parseError;
    }

    await addQuizToFirestore(parsedQuizData);
    return parsedQuizData;
  } catch (error) {
    console.error("Erreur lors de la génération du quiz :", error);
    throw new Error("Erreur lors de la génération du quiz.");
  }
};

// Fonction pour ajouter les quiz à Firestore
export const addQuizToFirestore = async (quizData) => {
  try {
    const docRef = await addDoc(collection(db, "quiztest"), quizData);
    console.log("Document ajouté avec l'ID :", docRef.id);
    return docRef.id;
  } catch (error) {
    console.error("Erreur lors de l'ajout du quiz à Firestore :", error);
    throw new Error("Erreur lors de l'ajout du quiz à Firestore.");
  }
};


// Fonction pour analyser les feedbacks et ratings d'une simulation
export const analyzeSimulationFeedback = async (currentUser) => {
  if (!currentUser) return;

  try {
    const userDoc = doc(db, "simulations", currentUser.email);
    const userSnapshot = await getDoc(userDoc);

    if (userSnapshot.exists()) {
      const simulationData = userSnapshot.data();
      const feedbacksAndRatings = simulationData.detail.map((question) => ({
        feedback: question.feedback,
        rating: question.rating,
      }));

      const prompt = `
        Analysez les feedbacks et ratings suivants pour générer les points à améliorer, les points forts et le score global :
        ${JSON.stringify(feedbacksAndRatings, null, 2)}
      `;

      const openAiResponse = await sendToOpenAI(prompt);

      let analysisResult;
      try {
        analysisResult = JSON.parse(openAiResponse);
      } catch (jsonError) {
        console.error("Erreur de parsing JSON:", jsonError);
        console.log("Réponse OpenAI non valide:", openAiResponse);
        throw new Error("Erreur de parsing JSON");
      }

      const globalScore =
        feedbacksAndRatings.reduce(
          (sum, question) => sum + question.rating,
          0
        ) / feedbacksAndRatings.length;

      await updateDoc(userDoc, {
        pointsaameliorer: analysisResult.pointsaameliorer,
        pointsforts: analysisResult.pointsforts,
        globaleScore: globalScore,
      });

      return analysisResult;
    } else {
      console.error("Aucune simulation trouvée pour cet utilisateur");
    }
  } catch (error) {
    console.error(
      "Erreur lors de l'analyse des feedbacks de simulation :",
      error
    );
  }
};

// ---------------generateInterviewQuestionsoffre --------------------

export const generateInterviewQuestionsoffre = async (offreId) => {
  try {
    const offreRef = doc(db, "offres_emploi", offreId);
    const offreSnap = await getDoc(offreRef);

    if (!offreSnap.exists()) {
      throw new Error("Offre d'emploi non trouvée");
    }

    const { titre, description } = offreSnap.data();

    // Construction du prompt pour OpenAI
    const prompt = `
      Vous êtes un recruteur spécialisé dans le domaine du ${titre}. En vous basant sur la description suivante, générez 10 questions d'entretien adaptées pour évaluer un candidat pour ce poste :
      
      Description du poste : 
      ${description}
    `;

    // Appel à OpenAI pour générer les questions
    const generatedQuestions = await sendToOpenAI(prompt);

    // Séparer les questions générées par les sauts de ligne
    const questionsArray = generatedQuestions.split('\n').map(question => question.trim()).filter(question => question);

    // Mise à jour du document Firestore avec les nouvelles questions
    await updateDoc(offreRef, { l_interview: questionsArray });

    return questionsArray;
  } catch (error) {
    console.error("Erreur lors de la génération des questions d'entretien :", error);
    throw error;
  }
};

//----------------------------- Anlyse Interview des candidat par offre emploi ----------

export const analyzeAndUpdateInterviewFeedback = async () => {
  try {
    const auth = getAuth(); 
    const currentUser = auth.currentUser;

    if (!currentUser) {
      console.error("Utilisateur non connecté");
      return []; // Retourne un tableau vide si l'utilisateur n'est pas connecté
    }

    const offresCollection = collection(db, "offres_emploi");
    const activeOffresQuery = query(
      offresCollection,
      where("active", "==", true),
      where("ownerid", "==", currentUser.email) // Filtrer par ownerid correspondant à l'email de l'utilisateur connecté
    );
    const activeOffresSnapshot = await getDocs(activeOffresQuery);

    const results = [];

    for (const offreDoc of activeOffresSnapshot.docs) {
      const offreData = offreDoc.data();
      const { interview_session, l_candidats } = offreData;

      if (!interview_session || interview_session.length === 0 || !l_candidats) continue;

      const candidats = [];
      let cumulNotes = 0;
      const cumulCommentaires = [];

      for (let i = 0; i < interview_session.length; i++) {
        const { idcandidat, question, reponse } = interview_session[i];

        const prompt = `
          Vous êtes un recruteur expérimenté. Analysez la réponse suivante par rapport à la question posée.

          Question: "${question}"
          Réponse: "${reponse}"

          Pour chaque question, veuillez attribuer une note sur 10 et fournir un commentaire constructif.

          Après avoir analysé toutes les réponses aux questions, veuillez également fournir une note globale en pourcentage (%) et un commentaire global reflétant l'ensemble des réponses du candidat.

          Format JSON attendu pour chaque question:
          {
            "noteQuestion": "score sur 10",
            "commentaireQuestion": "feedback pour cette question",
            "noteGlobalePourcentage": "score global en pourcentage (%)",
            "commentaireGlobal": "feedback global"
          }
        `;

        const analysisResult = await sendToOpenAI(prompt);

        let parsedResult;
        try {
          parsedResult = JSON.parse(analysisResult);
        } catch (jsonError) {
          console.error("Erreur de parsing JSON:", jsonError);
          console.log("Réponse OpenAI non valide:", analysisResult);
          continue;
        }

        const { noteQuestion, commentaireQuestion, noteGlobalePourcentage, commentaireGlobal } = parsedResult;

        // Mettre à jour les champs `note` et `commentaire` dans `interview_session` pour chaque question
        interview_session[i].note = noteQuestion;
        interview_session[i].commentaire = commentaireQuestion;

        // Accumuler les notes pour une évaluation globale
        cumulNotes += noteQuestion;
        cumulCommentaires.push(commentaireQuestion);

        const candidateIndex = l_candidats.findIndex(candidate => candidate.email === idcandidat);
        if (candidateIndex !== -1 && i === interview_session.length - 1) { // Si c'est la dernière question
          const moyenneNote = (cumulNotes / (interview_session.length * 10)) * 100;

          // Mettre à jour les champs globaux pour le candidat
          l_candidats[candidateIndex].interviewnote = noteGlobalePourcentage || moyenneNote.toFixed(2) + "%";
          l_candidats[candidateIndex].interviewcomment = commentaireGlobal || cumulCommentaires.join(" ");

          candidats.push({
            name: l_candidats[candidateIndex].name,
            score: noteGlobalePourcentage || moyenneNote.toFixed(2) + "%",
            commentaire: commentaireGlobal || cumulCommentaires.join(" "),
          });
        }
      }

      if (candidats.length > 0) {
        results.push({
          titre: offreData.titre,
          candidats: candidats,
        });
      }

      await updateDoc(doc(db, "offres_emploi", offreDoc.id), { 
        interview_session,
        l_candidats 
      });
    }

    return results; // Retourne le tableau des résultats
  } catch (error) {
    console.error("Erreur lors de l'analyse et de la mise à jour des feedbacks d'entretien:", error);
    throw error;
  }
};

// ---------------------------- Fonction principale pour analyser les expériences des utilisateurs

export const analyzeCandidateExperiences = async (offreId) => {
  try {
    // Récupération des données de l'offre d'emploi
    const offreRef = doc(db, "offres_emploi", offreId);
    const offreSnap = await getDoc(offreRef);

    if (!offreSnap.exists()) {
      throw new Error("Offre d'emploi non trouvée");
    }

    const { description } = offreSnap.data();

    // Affichage de l'offre d'emploi dans la console
    console.log("Offre d'emploi récupérée :", offreSnap.data());

    // Récupération des utilisateurs avec des expériences non vides
    const usersCollection = collection(db, "Users");
    const usersQuery = query(usersCollection, where("experiences", "!=", []));
    const usersSnapshot = await getDocs(usersQuery);

    const candidates = [];

    usersSnapshot.forEach((userDoc) => {
      const userData = userDoc.data();

      // Vérification que l'utilisateur a bien des expériences valides
      if (Array.isArray(userData.experiences) && userData.experiences.length > 0) {
        const validExperiences = userData.experiences.filter(
          exp => exp.company && exp.duration && exp.position
        );

        if (validExperiences.length > 0) {
          candidates.push({
            userID: userDoc.id,
            name: userData.name,
            experiences: validExperiences,
          });
        }
      }
    });

    if (candidates.length === 0) {
      console.log("Aucun candidat avec des expériences valides trouvé.");
      return [];
    }

    // Analyser les expériences avec OpenAI
    const analyses = await Promise.all(
      candidates.map(async (candidate) => {
        const experienceText = candidate.experiences
          .map((exp) => `- Entreprise : ${exp.company}, Durée : ${exp.duration}, Poste : ${exp.position}`)
          .join("\n");

        const prompt = `
          Vous êtes un recruteur expérimenté. Analysez l'expérience professionnelle suivante par rapport à la description de poste ci-dessous :

          Description du poste :
          ${description}

          Expérience professionnelle :
          ${experienceText}

          Pour chaque expérience, veuillez fournir un commentaire sur sa pertinence par rapport au poste, et attribuer un score de pertinence sur 100.

          Format JSON attendu :
          {
            "userID": "${candidate.userID}",
            "pertinence": "score de pertinence sur 100",
            "commentaire": "commentaire sur la pertinence"
          }
        `;

        // Affichage du prompt envoyé à OpenAI dans la console
        console.log("Prompt envoyé à OpenAI :", prompt);

        const openAiResponse = await sendToOpenAI(prompt);

        let parsedResult;
        try {
          parsedResult = JSON.parse(openAiResponse);
        } catch (jsonError) {
          console.error("Erreur de parsing JSON:", jsonError);
          console.log("Réponse OpenAI non valide:", openAiResponse);
          return null;
        }

        return {
          userID: candidate.userID,
          pertinence: parsedResult.pertinence,
          commentaire: parsedResult.commentaire,
        };
      })
    );

    // Filtrer les analyses nulles et trier les résultats par pertinence décroissante
    const sortedResults = analyses
      .filter((analysis) => analysis !== null)
      .sort((a, b) => b.pertinence - a.pertinence)
      .slice(0, 10); // Limiter à 10 résultats

    return sortedResults;
  } catch (error) {
    console.error("Erreur lors de l'analyse des expériences des candidats :", error);
    throw error;
  }
};
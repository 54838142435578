// src/api/googleconfig.js

const googleConfig = {
  apiKey: "AIzaSyBoH7hepy8u2DkK5RozdyKS3Y7XTFK7J1k",

  speechToTextApiKey: "AIzaSyBoH7hepy8u2DkK5RozdyKS3Y7XTFK7J1k",
  videoIntelligenceApiKey: "AIzaSyBoH7hepy8u2DkK5RozdyKS3Y7XTFK7J1k",
  textToSpeechApiKey: "AIzaSyBoH7hepy8u2DkK5RozdyKS3Y7XTFK7J1k",

  speechToTextEndpoint: "https://speech.googleapis.com/v1/speech:recognize",
  videoIntelligenceEndpoint:
    "https://videointelligence.googleapis.com/v1/videos:annotate",
  textToSpeechEndpoint:
    "https://texttospeech.googleapis.com/v1/text:synthesize", // Ajout de l'endpoint Text-to-Speech
};

export default googleConfig;

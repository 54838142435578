import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import { useAuth } from "../authContext";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuIcon from "@mui/icons-material/Menu";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import ChatIcon from "@mui/icons-material/Chat";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { fetchUserSubscriptions } from "../fetchUserSubscriptions";
import { collection, getDoc, doc } from "firebase/firestore";
import { db } from "../firebaseConfig";

const LogoLink = styled(Link)({
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
  color: "inherit",
});

const LogoImg = styled("img")({
  maxWidth: 40,
  marginRight: 10,
});

const Spacer = styled("div")({
  flexGrow: 1,
});

const DrawerList = styled("div")(({ theme }) => ({
  width: 250,
}));

const NavBar = () => {
  const { currentUser, logout } = useAuth();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error("Failed to log out", error);
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  useEffect(() => {
    const checkSubscriptionAndCategory = async () => {
      if (currentUser) {
        try {
          const subscriptions = await fetchUserSubscriptions(() => {});
          const activeSubscription = subscriptions.find(sub => sub.status === "active");
          setHasActiveSubscription(!!activeSubscription);

          const userDoc = doc(db, "Users", currentUser.email);
          const userSnapshot = await getDoc(userDoc);
          if (userSnapshot.exists()) {
            const userData = userSnapshot.data();
            setIsAdmin(
              userData.category === "Supadmin" || userData.category === "Admin"
            );
          }
        } catch (err) {
          console.error("Erreur lors de la vérification des abonnements ou des catégories:", err);
          setHasActiveSubscription(false);
          setIsAdmin(false);
        }
      } else {
        setHasActiveSubscription(false);
        setIsAdmin(false);
      }
    };

    checkSubscriptionAndCategory();
  }, [currentUser]);

  const sideList = () => (
    <DrawerList
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button component={Link} to="/dashboard">
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Tableau de bord" />
        </ListItem>
        {hasActiveSubscription && (
          <>
            <ListItem button component={Link} to="/preparation">
              <ListItemIcon>
                <DirectionsRunIcon />
              </ListItemIcon>
              <ListItemText primary="Préparation" />
            </ListItem>
            <ListItem button component={Link} to="/simulations">
              <ListItemIcon>
                <ChatIcon />
              </ListItemIcon>
              <ListItemText primary="Simulation" />
            </ListItem>
            <ListItem button component={Link} to="/analysis">
              <ListItemIcon>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText primary="Analyse" />
            </ListItem>
            {isAdmin && (
              <ListItem button component={Link} to="/dashboardadmin">
                <ListItemIcon>
                  <AdminPanelSettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Administration" />
              </ListItem>
            )}
          </>
        )}
        <ListItem button component={Link} to="/profile">
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText primary="Profil" />
        </ListItem>
        <ListItem button component={Link} to="/subscriptionPlans">
          <ListItemIcon>
            <CreditCardIcon />
          </ListItemIcon>
          <ListItemText primary="Abonnement" />
        </ListItem>
        {currentUser && (
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Déconnexion" />
          </ListItem>
        )}
      </List>
    </DrawerList>
  );

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <LogoLink to="/">
            <LogoImg src="/logojobjourneyofficiel.png" />
            <Typography variant="h6" component="div">
              JobJourney
            </Typography>
          </LogoLink>
          <Spacer />
          {currentUser && (
            <Typography variant="body1" color="inherit" style={{ marginRight: 16 }}>
              {currentUser.displayName} {/* ou currentUser.email si vous souhaitez afficher l'email */}
            </Typography>
          )}
          {currentUser && (
            <IconButton
              color="inherit"
              onClick={handleLogout}
              aria-label="logout"
            >
              <ExitToAppIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        {sideList()}
      </Drawer>
    </>
  );
};

export default NavBar;
